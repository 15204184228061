var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "store-add" },
    [
      _c(
        "el-dialog",
        {
          directives: [
            {
              name: "dialogDrag",
              rawName: "v-dialogDrag",
              value: true,
              expression: "true",
            },
          ],
          attrs: {
            visible: _vm.OpenStoreAdd,
            title: "新增仓库",
            width: "800px",
            height: "600px",
            "before-close": _vm.handleClose,
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                model: _vm.form,
                rules: _vm.rules,
                "label-width": "100px",
              },
            },
            [
              _c("div", { staticClass: "inforTitle" }, [_vm._v("基本信息")]),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "仓库编码", prop: "storeNo" } },
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: "请输入仓库编码",
                              maxlength: "11",
                            },
                            model: {
                              value: _vm.form.storeNo,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "storeNo", $$v)
                              },
                              expression: "form.storeNo",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "仓库名称", prop: "storeName" } },
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: "请输入仓库名称",
                              maxlength: "11",
                            },
                            model: {
                              value: _vm.form.storeName,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "storeName", $$v)
                              },
                              expression: "form.storeName",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "所属部门", prop: "deptId" } },
                        [
                          _c("treeselect", {
                            attrs: {
                              options: _vm.depOptions,
                              "show-count": true,
                              placeholder: "请选择所属部门",
                              normalizer: _vm.normalizer,
                            },
                            on: {
                              blur: function ($event) {
                                return _vm.$refs.form.validateField("deptId")
                              },
                            },
                            model: {
                              value: _vm.form.deptId,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "deptId", $$v)
                              },
                              expression: "form.deptId",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "仓库类型", prop: "storeType" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticClass: "select",
                              attrs: {
                                placeholder: "请选择仓库类型",
                                clearable: "",
                              },
                              model: {
                                value: _vm.form.storeType,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "storeType", $$v)
                                },
                                expression: "form.storeType",
                              },
                            },
                            _vm._l(_vm.dict.type.store_type, function (dict) {
                              return _c("el-option", {
                                key: dict.value,
                                attrs: {
                                  label: dict.label,
                                  value: parseInt(dict.value),
                                },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 20 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "仓库状态" } },
                        [
                          _c(
                            "el-radio-group",
                            {
                              model: {
                                value: _vm.form.status,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "status", $$v)
                                },
                                expression: "form.status",
                              },
                            },
                            _vm._l(_vm.dict.type.store_status, function (dict) {
                              return _c(
                                "el-radio",
                                {
                                  key: dict.value,
                                  attrs: { label: parseInt(dict.value) },
                                },
                                [_vm._v(_vm._s(dict.label))]
                              )
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "允许采购", prop: "isAllowPurchase" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.form.isAllowPurchase,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "isAllowPurchase", $$v)
                        },
                        expression: "form.isAllowPurchase",
                      },
                    },
                    _vm._l(
                      _vm.dict.type.App_is_allow_purchase,
                      function (dict) {
                        return _c(
                          "el-radio",
                          {
                            key: dict.value,
                            attrs: { label: parseInt(dict.value) },
                          },
                          [_vm._v(_vm._s(dict.label))]
                        )
                      }
                    ),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "允许销售", prop: "isAllowSale" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.form.isAllowSale,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "isAllowSale", $$v)
                        },
                        expression: "form.isAllowSale",
                      },
                    },
                    _vm._l(_vm.dict.type.App_is_allow_sale, function (dict) {
                      return _c(
                        "el-radio",
                        {
                          key: dict.value,
                          attrs: { label: parseInt(dict.value) },
                        },
                        [_vm._v(_vm._s(dict.label))]
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "允许负库存", prop: "isAllowMinus" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.form.isAllowMinus,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "isAllowMinus", $$v)
                        },
                        expression: "form.isAllowMinus",
                      },
                    },
                    _vm._l(_vm.dict.type.App_is_allow_minus, function (dict) {
                      return _c(
                        "el-radio",
                        {
                          key: dict.value,
                          attrs: { label: parseInt(dict.value) },
                        },
                        [_vm._v(_vm._s(dict.label))]
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "计价方式", prop: "costMode" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.form.costMode,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "costMode", $$v)
                        },
                        expression: "form.costMode",
                      },
                    },
                    _vm._l(_vm.dict.type.cost_mode, function (dict) {
                      return _c(
                        "el-radio",
                        {
                          key: dict.value,
                          attrs: { label: parseInt(dict.value) },
                        },
                        [_vm._v(_vm._s(dict.label))]
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
              _c("div", { staticClass: "inforTitle" }, [_vm._v("联系信息")]),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "仓库负责人",
                            prop: "storeEmployeeId",
                          },
                        },
                        [
                          _c("treeselect", {
                            attrs: {
                              options: _vm.contactsList,
                              "show-count": true,
                              placeholder: "请选择仓库负责人",
                              normalizer: _vm.normalizerContactsList,
                            },
                            on: {
                              blur: function ($event) {
                                return _vm.$refs.form.validateField(
                                  "storeEmployeeId"
                                )
                              },
                            },
                            model: {
                              value: _vm.form.storeEmployeeId,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "storeEmployeeId", $$v)
                              },
                              expression: "form.storeEmployeeId",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "联系电话", prop: "storeTel" } },
                        [
                          _c("el-input", {
                            attrs: { maxlength: "11" },
                            model: {
                              value: _vm.form.storeTel,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "storeTel", $$v)
                              },
                              expression: "form.storeTel",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "地址", prop: "regionId" } },
                        [
                          _c("el-cascader", {
                            staticClass: "address",
                            attrs: {
                              options: _vm.provinceList,
                              props: _vm.defaultProp,
                              maxlength: "80",
                              placeholder: "请选择地址",
                              filterable: "",
                              remote: "",
                              "reserve-keyword": "",
                              clearable: "",
                            },
                            model: {
                              value: _vm.form.regionId,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "regionId", $$v)
                              },
                              expression: "form.regionId",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "详细地址", prop: "classNo" } },
                        [
                          _c("el-input", {
                            attrs: { maxlength: "200" },
                            model: {
                              value: _vm.form.storeAddress,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "storeAddress", $$v)
                              },
                              expression: "form.storeAddress",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.submitForm } },
                [_vm._v("确 定")]
              ),
              _c("el-button", { on: { click: _vm.handleClose } }, [
                _vm._v("取 消"),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }