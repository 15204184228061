<template>
  <div>
    <el-dialog
      title="选择优惠券"
      :visible="OpenCoupon"
      :before-close="close"
      v-dialogDrag="true"
      width="70%"
    >
      <div v-show="step == 1" class="x-start diaBox">
        <div class="tableBox">
          <div class="t">
            <el-form
              :model="queryParams"
              ref="queryForm"
              size="small"
              :inline="true"
              v-show="showSearch"
              label-width="68px"
              @submit.native.prevent
            >
              <el-form-item label="" prop="query">
                <el-input
                  v-model="queryParams.query"
                  placeholder="请搜索优惠券编号/优惠券名称"
                  clearable
                  style="width: 240px"
                  @keyup.enter.native="handleQuery"
                />
              </el-form-item>
              <el-form-item>
                <el-button
                  class="marL10"
                  type="primary"
                  icon="el-icon-search"
                  size="mini"
                  @click="handleQuery"
                  >搜索
                </el-button>
              </el-form-item>
            </el-form>
          </div>
          <div class="b marT10">
            <div class="couponData">
              <!-- 表格数据 -->
              <el-table
                v-loading="loading"
                :data="CouponData"
                @selection-change="handleSelectionChange"
                border
                height="400"
                max-height="400"
                class="table"
                ref="table"
                @row-click="handleRowClick"
                @select-all="tabSelectAll"
                @select="tabSelect"
              >
                <el-table-column
                  type="selection"
                  width="50"
                  align="center"
                  fixed="left"
                />
                <el-table-column
                  type="index"
                  width="50"
                  align="center"
                  label="序号"
                  fixed="left"
                />
                <el-table-column
                  label="优惠券方案编号"
                  align="center"
                  key="couponCaseNo"
                  prop="couponCaseNo"
                  width="200"
                  fixed="left"
                >
                  <template slot-scope="scope">
                    <el-button type="text"
                      >{{ scope.row.couponCaseNo }}
                    </el-button>
                  </template>
                </el-table-column>
                <el-table-column
                  label="优惠券方案名称"
                  align="center"
                  key="couponCaseName"
                  prop="couponCaseName"
                  width="200"
                  :show-overflow-tooltip="true"
                  fixed="left"
                />
                <el-table-column
                  label="优惠券类型"
                  align="center"
                  key="couponCaseType"
                  prop="couponCaseType"
                  width="120"
                  show-overflow-tooltip
                >
                  <template slot-scope="scope">
                    <dict-tag
                      :options="dict.type.coupon_case_type"
                      :value="scope.row.couponCaseType"
                    />
                  </template>
                </el-table-column>
                <el-table-column
                  label="优惠券形式"
                  align="center"
                  key="couponMode"
                  prop="couponMode"
                  width="120"
                  :show-overflow-tooltip="true"
                >
                  <template slot-scope="scope">
                    <dict-tag
                      :options="dict.type.coupon_mode"
                      :value="scope.row.couponMode"
                    />
                  </template>
                </el-table-column>
                <el-table-column
                  label="方案启用"
                  align="center"
                  key="isStop"
                  prop="isStop"
                  :show-overflow-tooltip="true"
                >
                  <template slot-scope="scope">
                    {{ scope.row.isStop ? '停用' : '启用' }}
                  </template>
                </el-table-column>
                <el-table-column
                  label="优惠券内容"
                  align="center"
                  key="couponDesc"
                  prop="couponDesc"
                  width="200"
                  :show-overflow-tooltip="true"
                />
                <el-table-column
                  label="生效时间"
                  align="center"
                  key="validBegTime"
                  prop="validBegTime"
                  width="160"
                  :show-overflow-tooltip="true"
                />
                <el-table-column
                  label="失效时间"
                  align="center"
                  key="validEndTime"
                  prop="validEndTime"
                  width="160"
                  :show-overflow-tooltip="true"
                />
                <el-table-column
                  label="优惠券状态"
                  align="center"
                  key="couponStatus"
                  prop="couponStatus"
                  width="120"
                  :show-overflow-tooltip="true"
                />
                <el-table-column
                  label="优惠券发放总数"
                  align="center"
                  key="couponCount"
                  prop="couponCount"
                  width="120"
                  :show-overflow-tooltip="true"
                />
                <el-table-column
                  label="作废"
                  align="center"
                  key="scrapCount"
                  prop="scrapCount"
                  :show-overflow-tooltip="true"
                />
                <el-table-column
                  label="已领"
                  align="center"
                  key="drawCount"
                  prop="drawCount"
                  :show-overflow-tooltip="true"
                />
                <el-table-column
                  label="已核销"
                  align="center"
                  key="usedCount"
                  prop="usedCount"
                  :show-overflow-tooltip="true"
                />
                <el-table-column
                  label="销售"
                  align="center"
                  key="sellCount"
                  prop="sellCount"
                  :show-overflow-tooltip="true"
                />
                <el-table-column
                  label="销售退回"
                  align="center"
                  key="sellBackCount"
                  prop="sellBackCount"
                  :show-overflow-tooltip="true"
                />
                <el-table-column
                  label="转增"
                  align="center"
                  key="giveCount"
                  prop="giveCount"
                  :show-overflow-tooltip="true"
                />
                <el-table-column
                  label="创建人"
                  align="center"
                  key="createBy"
                  prop="createBy"
                  :show-overflow-tooltip="true"
                />
                <el-table-column
                  label="创建时间"
                  align="center"
                  key="createTime"
                  prop="createTime"
                  width="160"
                  :show-overflow-tooltip="true"
                />
                <el-table-column
                  label="修改人"
                  align="center"
                  key="updateBy"
                  prop="updateBy"
                  :show-overflow-tooltip="true"
                />
                <el-table-column
                  label="修改时间"
                  align="center"
                  key="updateTime"
                  prop="updateTime"
                  width="160"
                  :show-overflow-tooltip="true"
                />
              </el-table>
              <div class="tag" @click="showPitchData" ref="tag">
                <i class="el-icon-arrow-left" v-show="!openPitch"></i>
                <i class="el-icon-arrow-right" v-show="openPitch"></i>
                <div class="right">
                  <div>已</div>
                  <div>选</div>
                  <span>{{ ids.length }}</span>
                </div>
              </div>
              <div class="pitch-data" v-show="openPitch">
                <div class="pitch-title">
                  <div class="left">选中项</div>
                  <div class="right" @click="delPitchGoods('#')">清空</div>
                </div>
                <el-scrollbar class="scrollbarPitch">
                  <div class="itemList">
                    <div
                      class="item-goods"
                      v-for="item in selectAllData"
                      :key="item.couponCaseId"
                    >
                      <div class="name">{{ item.couponCaseName }}</div>
                      <div
                        class="el-icon-close"
                        @click="delPitchGoods(item)"
                      ></div>
                    </div>
                  </div>
                </el-scrollbar>
              </div>
            </div>
            <div class="goods-footer">
              <div class="left x-x" v-if="!isRadio && total > 0">
                <span class="select">
                  已选
                  <span class="num">{{ this.ids.length }}</span
                  >条
                </span>
                <div>
                  <span class="checkAll" v-show="!isCheckAll" @click="selectAll"
                    >选择全部</span
                  >
                  <span class="checkAll" v-show="isCheckAll" @click="selectAll"
                    >取消选择</span
                  >
                </div>
              </div>
              <div v-if="isRadio"></div>
              <!-- 分页 -->
              <pagination
                v-show="total > 0"
                :total="total"
                :page.sync="queryParams.pageNum"
                :limit.sync="queryParams.pageSize"
                @pagination="getList"
              />
            </div>
          </div>
        </div>
      </div>
      <div
        v-if="(isSuingEditorIsMultiple || isSuingEditorIsRadio) && step == 2"
      >
        <!-- 优惠券 -->
        <div class="marB20">
          <span class="fontS14 marR20">优惠券:</span>
          <span class="fontS14" v-if="isSuingEditorIsRadio">{{
            selectData.length > 0 ? selectData[0].couponCaseName : ''
          }}</span>
          <span class="fontS14" v-if="isSuingEditorIsMultiple">{{
            couponNameMultiple
          }}</span>
        </div>
        <!-- 投放张数 -->
        <div
          class="marB20"
          v-show="isPage === 'drawCouponCenter' && !isSuingEditorIsMultiple"
        >
          <span class="fontS14 marR20">投放张数:</span>
          <el-input
            class="w120 marR10"
            type="number"
            v-model="selectData[0].couponEmitQty"
          />
        </div>
        <!-- 限领张数 -->
        <div
          class="marB20 x-x"
          v-show="isPage === 'drawCouponCenter' && !isSuingEditorIsMultiple"
        >
          <span class="fontS14 marR20">限领张数:</span>
          <div>
            <el-radio-group v-model="selectData[0].couponDrawMode">
              <el-radio :label="0">每天</el-radio>
              <el-radio :label="1">每周</el-radio>
              <el-radio :label="2">每月</el-radio>
              <el-radio :label="3">总共</el-radio>
            </el-radio-group>
            <span class="marL20">
              <el-input
                class="w120"
                v-model="selectData[0].couponEveryDrawQty"
              ></el-input>
              每人限领张数
            </span>
          </div>
        </div>
        <!-- 发放数量 -->
        <div
          class="marB20"
          v-show="isPage !== 'drawCouponCenter' && !isSuingEditorIsMultiple"
        >
          <span class="fontS14 marR20">发放数量:</span>
          <el-input
            v-if="selectData.length > 0"
            class="inputWidth marR10"
            type="number"
            v-model="selectData[0].couponEmitQty"
            :disabled="selectData[0].couponEmitMode != 0"
          />
          <span class="fontS14 annotateGrey marR20">(单个会员)</span>
        </div>
        <!-- 发放方式 -->
        <div
          class="marB20 x-x"
          v-show="isPage !== 'drawCouponCenter' && !isSuingEditorIsMultiple"
        >
          <span class="fontS14 marR20">发放方式:</span>
          <div>
            <el-radio-group v-model="selectData[0].couponEmitMode">
              <el-radio :label="0">即时发放</el-radio>
              <el-radio :label="1">按天分期发放</el-radio>
              <el-radio :label="2">按周分期发放</el-radio>
              <el-radio :label="3">按月分期发放</el-radio>
            </el-radio-group>
            <div
              class="marT10 distributionMethod"
              v-show="
                selectData[0].couponEmitMode == 1 ||
                  selectData[0].couponEmitMode == 2 ||
                  selectData[0].couponEmitMode == 3
              "
            >
              <span class="fontS14 marR20">每次发放数量</span>
              <el-input
                class="marR10"
                type="number"
                v-model="selectData[0].couponEveryEmitQty"
                @blur="couponQuantity"
              />
              <span class="fontS14 marR20">, 分期次数</span>
              <el-input
                class="marR10"
                type="number"
                v-model="selectData[0].couponEmitTimes"
                @blur="couponQuantity"
              />
              <span class="fontS14 marR20">, 每次间隔</span>
              <el-input
                class="marR10"
                type="number"
                v-model="selectData[0].couponEmitInterval"
              />
              <span v-show="selectData[0].couponEmitMode == 1" class="fontS14"
                >天</span
              >
              <span v-show="selectData[0].couponEmitMode == 2" class="fontS14"
                >周</span
              >
              <span v-show="selectData[0].couponEmitMode == 3" class="fontS14"
                >月</span
              >
            </div>
          </div>
        </div>
        <!-- 生效方式 -->
        <div class="x-x marB20">
          <span class="fontS14 marR20">生效方式:</span>
         
        </div>
      </div>

      <div class="btn">
        <el-button
          v-if="(isSuingEditorIsRadio || isSuingEditorIsMultiple) && step == 2"
          @click="getStep('上一步')"
          size="mini"
          >上一步</el-button
        >
        <el-button
          v-if="(isSuingEditorIsRadio || isSuingEditorIsMultiple) && step == 1"
          @click="getStep('下一步')"
          size="mini"
          >下一步</el-button
        >
        <el-button
          v-show="
            (!isRadio && !isSuingEditorIsRadio && !isSuingEditorIsMultiple) ||
              (step == 2 && (isSuingEditorIsRadio || isSuingEditorIsMultiple))
          "
          type="primary"
          @click="getGoods"
          size="mini"
          >确定</el-button
        >
        <el-button @click="close" size="mini">取消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { CouponList } from '@/api/marketing/coupon/case'
export default {
  name: 'SelectCoupon',
  dicts: [
    'coupon_mode', //优惠券形式
    'coupon_case_type' //优惠类型
  ],
  props: {
    //开启开关
    OpenCoupon: {
      type: Boolean,
      default: false
    },
    // 单选优惠券发放编辑
    isSuingEditorIsRadio: {
      type: Boolean,
      default: false
    },
    // 多选优惠券发放编辑
    isSuingEditorIsMultiple: {
      type: Boolean,
      default: false
    },
    // 是否单选，默认多选
    isRadio: {
      type: Boolean,
      default: false
    },
    //页面使用
    isPage: {
      type: String
    }
  },
  data () {
    return {
      //禁用即时生效、固定日期生效
      isSpecalTime: false,
      //多选优惠券的名称
      couponNameMultiple: '',
      // 查询参数
      queryParams: {
        pageNum: 1,
        pageSize: 15,
        isStop: false,
        isInvalid: 1
      },
      total: 0,
      // 显示搜索条件
      showSearch: true,
      loading: false,
      CouponData: [], // 优惠券列表
      selectData: [], // 列表选择的
      selectAllData: [], //全选全部数量
      ids: [], // 选择的ID列表
      //是否开关已选展示框
      openPitch: false,
      //判断当前是否全选数据
      isCheckAll: false,
      //定位已选图标的位置
      posiRight: '20px',
      step: 1
    }
  },
  watch: {
    OpenCoupon: {
      handler (val) {
        if (val) {
          this.step = 1
          this.isSpecalTime = false
          this.getList()
        }
      }
    }
  },
  methods: {
    //步骤
    getStep (val) {
      if (val == '上一步') {
        this.step--
        if (this.step < 1) {
          this.step = 1
        }
      } else {
        if (this.selectData.length <= 0) {
          this.$message.error('请选择优惠券')
          return
        }
        this.step++
        if (this.step > 2) {
          this.step = 2
        }
        if (
          this.isRadio &&
          this.isSuingEditorIsRadio &&
          this.selectData[0].isSpecalTime
        ) {
          this.isSpecalTime = true
          this.selectData[0].couponEffectMode = 1
          this.selectData[0].couponEffectDays = this.selectData[0].drawDay
          this.selectData[0].couponValidDays = this.selectData[0].validDay
        } else {
          this.isSpecalTime = false
        }
      }
    },
    //查询列表
    getList () {
      this.loading = true
      CouponList(this.queryParams).then(res => {
        this.CouponData = res.rows
        this.total = res.total
        this.loading = false
      })
    },
    //发放数量
    couponQuantity () {
      const { couponEveryEmitQty, couponEmitTimes } = this.selectData[0]
      if (couponEveryEmitQty && couponEmitTimes) {
        this.$set(
          this.selectData[0],
          'couponEmitQty',
          couponEveryEmitQty * couponEmitTimes
        )
      }
    },
    //选中的数据
    getGoods () {
      if (this.ids.length >= 1) {
        if (this.isSuingEditorIsRadio || this.isSuingEditorIsMultiple) {
          // 投放张数不能为空
          if (
            !this.selectData[0].couponEmitQty &&
            this.isPage === 'drawCouponCenter' &&
            !this.isSuingEditorIsMultiple
          ) {
            this.$message.error('投放张数不能为空')
            return
          }
          // 限领张数不能为空
          if (
            !this.selectData[0].couponEveryDrawQty &&
            this.isPage === 'drawCouponCenter' &&
            !this.isSuingEditorIsMultiple
          ) {
            this.$message.error('限领张数不能为空')
            return
          }
          // 发放数量不能为空
          if (
            !this.selectData[0].couponEmitQty &&
            this.isPage !== 'drawCouponCenter' &&
            !this.isSuingEditorIsMultiple
          ) {
            this.$message.error('发放数量不能为空')
            return
          }
          // 发放优惠券数量>优惠券剩余数量
          if (
            !this.isSuingEditorIsMultiple &&
            this.selectData[0].couponEmitQty > this.selectData[0].couponCount &&
            this.isPage !== 'drawCouponCenter'
          ) {
            this.$message.error('发放优惠券数量大于优惠券剩余数量')
            return
          }
          //发放方式不为空
          if (
            !this.isSuingEditorIsMultiple &&
            this.selectData[0].couponEmitMode != 0 &&
            this.isPage !== 'drawCouponCenter'
          ) {
            //每次发放数量不能为空
            if (!this.selectData[0].couponEveryEmitQty) {
              this.$message.error('每次发放数量不能为空')
              return
            }
            //分期次数不能为空
            if (!this.selectData[0].couponEmitTimes) {
              this.$message.error('分期次数不能为空')
              return
            }
            //每次间隔时间不能为空
            if (!this.selectData[0].couponEmitInterval) {
              this.$message.error('每次间隔时间不能为空')
              return
            }
          }
          //生效方式不为空

          //有效时长通等
          if (!this.selectData[0].couponValidDays) {
            this.$message.error('生效后有效时长不能为空')
            return
          }
          //延迟生效
          if (this.selectData[0].couponEffectMode == 1) {
            if (!this.selectData[0].couponEffectDays) {
              this.$message.error('领取多少天后生效不能为空')
              return
            }
          }
          //固定日期
          if (this.selectData[0].couponEffectMode == 2) {
            if (!this.selectData[0].couponEffectDate) {
              this.$message.error('固定日期不能为空')
              return
            }
          }
        }
        if (this.isSuingEditorIsMultiple) {
          // //发放方式111111111111111
          // let couponEmitMode = this.selectData[0].couponEmitMode
          // //每次发放数量
          // let couponEveryEmitQty = this.selectData[0].couponEveryEmitQty
          // //分期次数
          // let couponEmitTimes = this.selectData[0].couponEmitTimes
          // //每次间隔
          // let couponEmitInterval = this.selectData[0].couponEmitInterval
          //生效方式222
          let couponEffectMode = this.selectData[0].couponEffectMode
          //生效后有效时长
          let couponValidDays = this.selectData[0].couponValidDays
          //领取后多少天生效
          let couponEffectDays = this.selectData[0].couponEffectDays
          //固定日期
          let couponEffectDate = this.selectData[0].couponEffectDate
          this.selectData.forEach(item => {
            this.$set(item, 'couponEffectMode', couponEffectMode)
            this.$set(item, 'couponValidDays', couponValidDays)
            this.$set(item, 'couponEffectDays', couponEffectDays)
            this.$set(item, 'couponEffectDate', couponEffectDate)
          })
        }

        this.$emit('getCoupons', this.selectData)
      }

      this.close()
    },
    //点击行选中
    handleRowClick (row) {
      // 获取表格对象
      // 切换行选中状态
      if (this.isRadio) {
        this.$nextTick(() => {
          this.$refs.table.clearSelection()
          this.$refs.table.toggleRowSelection(row)
        })
      } else {
        this.$refs.table.toggleRowSelection(row)
      }
    },
    //关闭对话框
    close () {
      this.$emit('update:OpenCoupon', false)
    },
    /** 搜索按钮操作 */
    handleQuery () {
      this.queryParams.pageNum = 1
      this.getList()
    },
    //显示选中的商品
    showPitchData () {
      this.openPitch = !this.openPitch
      this.posiRight = this.posiRight === '20px' ? '238px' : '20px'
      this.$refs.tag.style.right = this.posiRight
    },
    // 多选框选中数据
    handleSelectionChange (selection) {
      this.selectData = selection
      this.ids = selection.map(item => item.goodsId)
      //发放编辑
      if (this.isSuingEditorIsRadio && this.selectData.length > 0) {
        this.$set(this.selectData[0], 'couponEmitMode', 0)
        this.$set(this.selectData[0], 'couponEffectMode', 0)
        this.$set(this.selectData[0], 'couponDrawMode', 3)
      } else if (this.isSuingEditorIsMultiple && this.selectData.length > 0) {
        //优惠券名称
        this.couponNameMultiple = this.selectData
          .map(person => person.couponCaseName)
          .join('，')
        this.$set(this.selectData[0], 'couponEffectMode', 0)
      }
    },
    //取消选中的商品
    delPitchGoods (row) {
      if (row === '#') {
        console.log(this.$refs.table)
        this.$nextTick(() => {
          this.$refs.table.clearSelection() //清空选中
        })
      } else {
        this.CouponData.map(item => {
          if (item.couponCaseId === row.couponCaseId) {
            this.$nextTick(() => {
              this.$refs.table.toggleRowSelection(item) //取消行选中
            })
          }
        })
      }
    },
    //点击全选所有数据
    selectAll () {
      this.isCheckAll = !this.isCheckAll
      if (this.isCheckAll) {
        this.goodsAllList.map(row => {
          for (let i = 0; i < this.selectAllData.length; i++) {
            if (row.goodsId === this.selectAllData[i].goodsId) return
          }
          this.$nextTick(() => {
            this.$refs.goodsTable.toggleRowSelection(row, this.isCheckAll) //全选
          })
        })
      } else {
        this.$nextTick(() => {
          this.$refs.goodsTable.clearSelection()
        })
      }
      this.getList()
    },
    tabSelectAll (selection) {
      if (this.isRadio) {
        if (selection.length > 1) {
          selection.length = 1
        }
      }
    },
    tabSelect (selection) {
      console.log('选择数量：')

      if (selection.length > 1) {
        if (this.isRadio) {
          console.log('选择数量2：', selection.length)
          let del_row = selection.shift()
          this.$refs.table.toggleRowSelection(del_row, false)
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-dialog__body {
  padding: 10px 20px !important;
}

.diaBox {
  .tableBox {
    width: 100%;
  }

  .couponData {
    display: flex;

    .table {
      flex: 5;
    }

    //右边选中区域
    .pitch-data {
      flex: 1;
      height: 605px;
      border-top: 1px solid #dfe6ec;
      border-right: 1px solid #dfe6ec;
      border-bottom: 1px solid #dfe6ec;

      .pitch-title {
        display: flex;
        justify-content: space-between;
        padding: 0 5px;
        height: 44px;
        line-height: 44px;
        border-bottom: 1px solid #dfe6ec;
        background-color: #f8f8f9;

        .left {
          font-weight: 700;
        }

        .right {
          color: #1890ff;
          cursor: pointer;
        }
      }

      //滚动条
      .scrollbarPitch {
        overflow: hidden;
        height: 561px;

        .itemList {
          .item-goods {
            padding-right: 8px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: 56px;
            background-image: linear-gradient(to bottom, #fff, #f5f5f5);
          }
        }
      }
    }
  }

  //选中的图标
  .tag {
    position: absolute;
    transform: translateY(-50%);
    right: 20px;
    top: 50%;
    display: flex;
    cursor: pointer;
    font-size: 16px;
    width: 60px;
    border: 1px solid #dfe6ec;
    border-radius: 5px;
    align-items: center;
    z-index: 9999;
    background-color: #fff;

    .right {
      display: flex;
      flex-direction: column;
      padding: 0 2px;
      text-align: center;
    }

    span {
      padding: 0 6px;
      text-align: center;
      line-height: 25px;
      height: 25px;
      border-radius: 50%;
      background-color: #2386ee;
      color: #fff;
      font-size: 14px;
    }
  }

  //表格底部区域
  .goods-footer {
    display: flex;
    justify-content: space-between;

    .left {
      padding: 32px 16px;

      .select {
        margin-right: 5px;

        .num {
          color: #1890ff;
        }
      }

      .checkAll {
        color: #1890ff;
        cursor: pointer;
      }
    }
  }
}
.w120 {
  width: 120px;
}
//输入框
::v-deep .el-input--medium .el-input__inner {
  height: 28px;
  line-height: 28px;
}
//输入框原生属性
/**
 * 解决el-input设置类型为number时，中文输入法光标上移问题
 **/
::v-deep .el-input__inner {
  line-height: 1px !important;
}

/**
 * 解决el-input设置类型为number时，去掉输入框后面上下箭头
 **/
::v-deep input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
//发放方式
.distributionMethod {
  width: 570px;
  height: 60px;
  background-color: rgb(229, 229, 229);
  font-size: 14px;
  margin-left: 8px;
  padding-top: 15px;
  padding-left: 20px;
  .el-input {
    width: 70px;
  }
}
//按钮
.btn {
  text-align: right;
  margin-top: 10px;
}
</style>
