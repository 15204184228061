<template>
  <!-- 属性值选择框 -->
  <div class="selectMachining">
    <el-dialog
            title="选择属性值"
            :visible="OpenMachining"
            :before-close="close"
            v-dialogDrag="true"
            width="40%"
        >
            <div class="x-start diaBox">
                <div class="tableBox">
                    <div class="t marB10">
                                <el-input
                                    v-model="machiningName"
                                    placeholder="请输入属性名称"
                                    clearable
                                    style="width: 240px"
                                />
                    </div>
                    <div class="b">
                        <div class="machiningData">
                            <!-- 表格数据 -->
                            <el-table
                                v-loading="loading"
                                :data="optionList"
                                @selection-change="handleSelectionChange"
                                border
                                height="400"
                                class="table"
                                ref="table"
                            >
                                <el-table-column
                                    type="selection"
                                    width="50"
                                    align="center"
                                    fixed="left"
                                />
                                <el-table-column
                                    type="index"
                                    width="50"
                                    align="center"
                                    label="序号"
                                    fixed="left"
                                />
                                <el-table-column
                                    label="属性名称"
                                    align="center"
                                    key="machiningName"
                                    prop="machiningName"
                                    width="160"
                                    :show-overflow-tooltip="true"
                                />
                                <el-table-column
                                    label="加价(元)"
                                    align="center"
                                    key="machiningPrice"
                                    prop="machiningPrice"
                                    width="120"
                                    :show-overflow-tooltip="true"
                                />
                                <el-table-column
                                    label="排序"
                                    align="center"
                                    key="sortNo"
                                    prop="sortNo"
                                    width="120"
                                    :show-overflow-tooltip="true"
                                />
                                <el-table-column
                                />
                            </el-table>
                            <div class="tag" @click="showPitchData" ref="tag">
                                <i class="el-icon-arrow-left" v-show="!openPitch"></i>
                                <i class="el-icon-arrow-right" v-show="openPitch"></i>
                                <div class="right">
                                    <div>已</div>
                                    <div>选</div>
                                    <span>{{ ids.length }}</span>
                                </div>
                            </div>
                            <div class="pitch-data" v-show="openPitch">
                                <div class="pitch-title">
                                    <div class="left">选中项</div>
                                    <div class="right" @click="delPitchGoods('#')">清空</div>
                                </div>
                                <el-scrollbar class="scrollbarPitch">
                                    <div class="itemList">
                                        <div
                                            class="item"
                                            v-for="item in selectData"
                                            :key="item.machiningId"
                                        >
                                            <div class="name">{{ item.machiningName }}</div>
                                            <div
                                                class="el-icon-close"
                                                @click="delPitchGoods(item)"
                                            ></div>
                                        </div>
                                    </div>
                                </el-scrollbar>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="btn">
                <el-button @click="close">取 消</el-button>
                <el-button type="primary" @click="getData">确 定</el-button>
            </div>
        </el-dialog>
  </div>
</template>

<script>
export default {
    name:'SelectMachining',
    props:{
        //属性值弹框开关
        OpenMachining:{
            type:Boolean,
            default:false,
        },
        //属性值数据
        machiningData:{
            type:Array
        }
    },
    data () {
        return {
            loading:false,//遮罩层
            selectData:[],//选中的数据
            ids:[],//选中数据的ids
            // 显示搜索条件
            showSearch: true,
            // 查询参数
            machiningName:undefined,
            //是否开关已选展示框
            openPitch: false,
            //判断当前是否全选数据
            isCheckAll: false,
            //定位已选图标的位置
            posiRight: '20px'
        }
    },
    computed: {
    // 搜索过滤显示数据
    optionList () {
      if (!this.machiningName) {
        return this.machiningData
      }
      const arr = this.machiningData.filter(v => v.machiningName.indexOf(this.machiningName) !== -1)
      return arr
    }
  },
    methods: {
        //关闭对话框
        close() {
            this.$emit('update:OpenMachining', false)
        },
        // 多选框选中数据
        handleSelectionChange(selection) {
            this.selectData = selection
            this.ids = selection.map(item => item.goodsId)
        },
        //选中的数据
        getData() {
            if (this.ids.length >= 1) this.$emit('getMachining', this.selectData)
            this.close()
            this.delPitchGoods('#')
        },
        //显示选中的商品
        showPitchData() {
            this.openPitch = !this.openPitch
            this.posiRight = this.posiRight === '20px' ? '200px' : '20px'
            this.$refs.tag.style.right = this.posiRight
        },
        //取消选中的商品
        delPitchGoods(row) {
            if (row === '#') {
                this.$nextTick(() => {
                    this.$refs.table.clearSelection() //清空选中
                })
            } else {
                this.machiningData.map(item => {
                    if (item.machiningId === row.machiningId) {
                        this.$nextTick(() => {
                            this.$refs.table.toggleRowSelection(item) //取消行选中
                        })
                    }
                })
            }
        },
    }
}
</script>

<style lang="scss" scoped>
::v-deep .el-dialog__body {
    padding: 10px 20px !important;
}
.el-form-item{
    margin-bottom: 10px;
}
.diaBox {
    .tableBox {
        width: 100%;
    }
    

    .machiningData {
        display: flex;

        .table {
            flex: 3;
        }

        //右边选中区域
        .pitch-data {
            flex: 1;
            height: 400px;
            border-top: 1px solid #dfe6ec;
            border-right: 1px solid #dfe6ec;
            border-bottom: 1px solid #dfe6ec;

            .pitch-title {
                display: flex;
                justify-content: space-between;
                padding: 0 5px;
                height: 44px;
                line-height: 44px;
                border-bottom: 1px solid #dfe6ec;
                background-color: #f8f8f9;

                .left {
                    font-weight: 700;
                }

                .right {
                    color: #1890ff;
                    cursor: pointer;
                }
            }

            //滚动条
            .scrollbarPitch {
                overflow: hidden;
                height: 356px;

                .itemList {
                    .item {
                        padding:0 8px;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        height: 56px;
                        background-image: linear-gradient(to bottom, #fff, #f5f5f5);
                    }
                }
            }
        }
    }

    //选中的图标
    .tag {
        position: absolute;
        transform: translateY(-50%);
        right: 20px;
        top: 50%;
        display: flex;
        cursor: pointer;
        font-size: 16px;
        width: 50px;
        border: 1px solid #dfe6ec;
        border-radius: 5px;
        align-items: center;
        z-index: 9999;
        background-color: #fff;

        .right {
            display: flex;
            flex-direction: column;
            padding: 0 2px;
            text-align: center;
        }

        span {
            padding: 0 6px;
            text-align: center;
            line-height: 25px;
            height: 25px;
            border-radius: 50%;
            background-color: #2386ee;
            color: #fff;
            font-size: 14px;
        }
    }

}

//按钮
.btn {
    text-align: right;
    margin-top: 10px;
}

</style>