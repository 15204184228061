<template>
  <!-- 选择短信产品弹窗 -->
  <div class="selectProductDialog">
    <c-dialog :title="title" :showDialog.sync="openDialog" :width="1200">
      <template #content>
        <div class="search x-f">
          <el-input
            size="mini"
            v-model="queryParams.productName"
            placeholder="请输入产品名称"
            clearable
            @keyup.enter.native="getList()"
          />
          <el-button
            type="primary"
            icon="el-icon-search"
            size="mini"
            @click="getList()"
            >搜索</el-button
          >
        </div>
        <!-- 表格区域 -->
        <div class="table">
          <el-table
            @selection-change="handleSelectionChange"
            @select-all="tabSelectAll"
            @select="tabSelect"
            ref="multipleTable"
            border
            v-loading="loading"
            :data="productList"
            row-key="productId"
            height="570"
          >
            <el-table-column width="55" type="selection" align="center" />
            <el-table-column
              prop="productName"
              label="产品名称"
              align="center"
              :show-overflow-tooltip="true"
              width="260"
            ></el-table-column>
            <el-table-column
              prop="logoUrl"
              label="产品图片"
              align="center"
              width="100"
            >
              <template slot-scope="scope">
                <img
                  style="width: 50px; object-fit: cover; height: 50px"
                  :src="scope.row.logoUrl"
                />
              </template>
            </el-table-column>
            <el-table-column
              width="120"
              prop="productCalcType"
              label="计费模式"
              align="center"
              :show-overflow-tooltip="true"
            >
              <template slot-scope="scope">
                <span>{{
                  scope.row.productCalcType == 1 ? '包年包月' : '数量或次数'
                }}</span>
              </template>
            </el-table-column>
            <el-table-column
              prop="initializeUsers"
              label="初始用户数"
              align="center"
              width="120"
              :show-overflow-tooltip="true"
            ></el-table-column>
            <el-table-column
              prop="maxUsers"
              label="最大用户数"
              align="center"
              width="120"
              :show-overflow-tooltip="true"
            ></el-table-column>
            <el-table-column
              prop="initializeShops"
              label="初始门店数"
              align="center"
              width="120"
              :show-overflow-tooltip="true"
            ></el-table-column>
            <el-table-column
              prop="maxShops"
              label="最大门店数"
              align="center"
              width="120"
              :show-overflow-tooltip="true"
            ></el-table-column>
            <el-table-column
              prop="status"
              label="上线状态"
              align="center"
              key="status"
              width="120"
            >
              <template slot-scope="scope">
                <el-switch
                  v-model="scope.row.status"
                  disabled
                  active-value="0"
                  inactive-value="1"
                ></el-switch>
              </template>
            </el-table-column>
            <el-table-column
              prop="sort"
              label="排序"
              align="center"
              width="100"
            ></el-table-column>
            <el-table-column
              label="创建时间"
              width="155"
              align="center"
              prop="createTime"
            >
              <!-- <template slot-scope="scope">
                <span>{{ parseTime(scope.row.createTime) }}</span>
              </template> -->
            </el-table-column>
          </el-table>
          <pagination
            v-show="total > 0"
            :total="total"
            :page.sync="queryParams.pageNum"
            :limit.sync="queryParams.pageSize"
            @pagination="getList()"
          />
        </div>
      </template>
      <template #buttonList>
        <el-button size="mini" @click="close">取 消</el-button>
        <el-button size="mini" type="primary" @click="onSelect"
          >确 定</el-button
        >
      </template>
    </c-dialog>
  </div>
</template>

<script>
import { productListTree } from '@/api/tenant/product/saas.js' //接口api
import CDialog from '@/components/dialogTemplate/dialog/index.vue'
export default {
  name: 'SelectProductDialog',
  components: {
    CDialog
  },
  props: {
    //弹窗开关
    openDialog: {
      type: Boolean,
      default: true
    },
    productType: {
      type: Number,
      default: null
    },
    productTypes: {
      type: Array,
      default: null
    },
    isRadio: {
      type: Boolean,
      default: false
    },
    // 是否是APP
    isSunvunApp: {
      type: Boolean,
      default: false
    },
    status: {
      type: Number
    }
  },
  dicts: ['sys_normal_disable'],
  data () {
    return {
      title: '', // 弹框标题
      queryParams: {
        pageNum: 1,
        pageSize: 15
      },
      total: 1,
      productItem: undefined, //选中的产品
      productList: [],
      loading: false
    }
  },
  watch: {
    openDialog (newVal) {
      if (newVal) {
        this.getList()
      }
    },
    productType (nVal) {
      if (nVal == 3) {
        this.title = '选择短信产品'
      } else {
        this.title = '选择产品'
      }
    }
  },

  methods: {
    //获取产品列表
    async getList () {
      this.loading = true
      try {
        const { rows, total } = await productListTree({
          ...this.queryParams,
          productType: this.productType,
          productTypes: this.productTypes,
          isSunvunApp: this.isSunvunApp,
          status: this.status
        })
        this.productList = rows
        this.total = total
      } catch (error) {}
      this.loading = false
    },
    close () {
      this.$emit('update:openDialog', false)
    },
    onSelect () {
      this.$emit('update:openDialog', false)
      this.$emit('select', this.selectData)
    },
    //点击行选中
    // handleRowClick(row) {
    //     console.log('选中行====', row)
    //
    //     // 获取表格对象
    //     // 切换行选中状态
    //     this.$refs.multipleTable.toggleRowSelection(row)
    // },
    handleSelectionChange (data) {
      this.selectData = data
    },
    tabSelectAll (selection) {
      if (this.isRadio) {
        console.log('选择数量：', selection.length)
        if (selection.length > 1) {
          selection.length = 1
        }
      }
    },
    tabSelect (selection) {
      if (selection.length > 1) {
        if (this.isRadio) {
          console.log('选择数量2：', selection.length)
          let del_row = selection.shift()
          this.$refs.multipleTable.toggleRowSelection(del_row, false)
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.search {
  margin-bottom: 10px;

  .el-input {
    width: 200px;
    margin-right: 10px;
  }
}
</style>
