<template>
  <div>
    <el-dialog
      :visible="openProduceOrder"
      title="选择生产班次"
      :before-close="close"
      v-dialogDrag="true"
      width="40%"
    >
      <!-- 整个表格 -->
      <div class="vipLevetableCom" v-loading="loading">
        <div class="top">
          <!-- 搜索框 -->
          <div>
            <el-input
              class="searchInput"
              placeholder="请输入生产班次编号或名称"
              prefix-icon="el-icon-search"
              v-model="queryParams.query"
              size="small"
              @keyup.enter.native="handleQuery"
            >
            </el-input>
            <el-button
              type="primary"
              icon="el-icon-search"
              size="small"
              @click="handleQuery"
              >搜索</el-button
            >
          </div>
        </div>
        <el-table
          class="conTable"
          :data="tableData"
          tooltip-effect="dark"
          @selection-change="handleSelectionChange"
          border
          ref="multipleTable"
          @row-click="handleRowClick"
          max-height="400"
        >
          <el-table-column width="55" align="center" v-if="isSelectOrderRadio">
            <template v-slot="scope">
              <el-radio v-model="produceOrderItem" :label="scope.row">{{
                ''
              }}</el-radio>
            </template>
          </el-table-column>
          <el-table-column type="selection" width="50" align="center" v-else />
          <el-table-column align="center" type="index" width="80" label="序号">
          </el-table-column>
          <el-table-column
            align="center"
            prop="produceOrderNo"
            label="生产班次编号"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            prop="produceOrderName"
            label="生产班次名称"
            show-overflow-tooltip
            align="center"
          >
          </el-table-column>
        </el-table>
        <!-- 分页 -->
        <pagination
          v-show="total > 0"
          :total="total"
          :page.sync="queryParams.pageNum"
          :limit.sync="queryParams.pageSize"
          @pagination="getList"
        />
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="getConfirm">确 定</el-button>
        <el-button @click="close">取消</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { produceOrderList } from '@/api/produce/sets/produceOrder' //生产班次接口
export default {
  name: 'selectProduceOrder',
  props: {
    //弹窗信息开关
    openProduceOrder: {
      type: Boolean,
      default: false
    },
    //是否单选
    isSelectOrderRadio: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      produceOrderItem: undefined, //选中的数据   单选
      loading: false, //加载
      total: 0, //条数
      tableData: [], //生产班次列表
      selectAllData: [], //选中数组
      //分页查询参数
      queryParams: {
        pageNum: 1,
        pageSize: 15,
        query: undefined
      }
    }
  },

  watch: {
    openProduceOrder (val) {
      if (val) {
        this.getList()
      } else {
        this.reset()
      }
    }
  },
  methods: {
    //点击行选中
    handleRowClick (row) {
      // 获取表格对象
      // 切换行选中状态
      if (this.isSelectOrderRadio) {
        this.produceOrderItem = row
      } else {
        this.$refs.multipleTable.toggleRowSelection(row)
      }
    },
    //确定按钮
    getConfirm () {
      if (this.selectAllData.length >= 1)
        this.$emit('produceOrderListCom', this.selectAllData)
      this.$emit('produceOrderItemCom', this.produceOrderItem)
      this.close()
    },
    //关闭对话框
    close () {
      this.$emit('update:openProduceOrder', false)
    },
    // 多选框选中数据
    handleSelectionChange (selection) {
      this.selectAllData = selection
    },
    /** 查询分页列表 */
    getList () {
      this.loading = true
      produceOrderList(this.queryParams).then(response => {
        this.tableData = response.rows
        this.total = response.total
        this.loading = false
      })
    },
    /** 搜索按钮操作 */
    handleQuery () {
      this.queryParams.pageNum = 1
      this.getList()
    },
    //清空搜索框
    reset () {
      //单选数据
      this.produceOrderItem = undefined
      //选中数组
      this.selectAllData = []
      //生产班次列表
      this.tableData = []
      //列表条数
      this.total = 0
      //清空查询条件
      this.queryParams = {
        pageNum: 1,
        pageSize: 15,
        query: undefined
      }
    }
  }
}
</script>

<style lang="scss" scoped>
//整体盒子
.vipLevetableCom {
  display: flex;
  flex-direction: column;
  //顶部操作
  .top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
    flex-direction: row-reverse;
    //输入框
    .searchInput {
      width: 200px;
      margin-right: 5px;
    }
  }
  //表格显示
  .conTable {
    min-height: 400px;
    width: 100%;
  }
  ::v-deep.el-dialog__body {
    padding: 10px 20px;
  }
}
</style>
