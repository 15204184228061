<template>
  <div>
    <el-dialog
      :visible="OpenPackage"
      title="选择优惠券包"
      :before-close="close"
      v-dialogDrag="true"
      width="1000px"
    >
      <!-- 整个表格 -->
      <div class="vipLevetableCom" v-loading="loading">
        <div class="top">
          <!-- 搜索框 -->
          <div>
            <el-input
              class="searchInput"
              placeholder="请输入优惠券包名称"
              prefix-icon="el-icon-search"
              v-model="queryParams.packageName"
              size="small"
              @keyup.enter.native="handleQuery"
            >
            </el-input>
            <el-button
              type="primary"
              icon="el-icon-search"
              size="mini"
              @click="handleQuery"
              >搜索</el-button
            >
          </div>
        </div>
        <el-table
          :data="tableData"
          tooltip-effect="dark"
          style="width: 100%"
          @selection-change="handleSelectionChange"
          border
          max-height="700"
          ref="multipleTable"
          @row-click="handleRowClick"
        >
          <el-table-column
            width="50"
            align="center"
            v-if="isSelectPackageRadio"
          >
            <template v-slot="scope">
              <el-radio v-model="packageItem" :label="scope.row">{{
                ''
              }}</el-radio>
            </template>
          </el-table-column>
          <el-table-column
            type="selection"
            width="50"
            align="center"
            :reserve-selection="true"
            v-else
          />
          <el-table-column
            label="序号"
            align="center"
            width="80"
            type="index"
          />
          <el-table-column
            show-overflow-tooltip
            label="券包名称"
            align="center"
            prop="packageName"
          >
            <template slot-scope="scope">
              <el-link
                type="primary"
                @click="handleDetail(scope.row)"
                target="_blank"
              >
                {{ scope.row.packageName }}
              </el-link>
            </template>
          </el-table-column>
          <el-table-column
            label="状态"
            align="center"
            prop="isStop"
            width="100"
          >
            <template slot-scope="scope">
              <span>{{ scope.row.isStop == 1 ? '启用' : '禁用' }}</span>
            </template>
          </el-table-column>
          <el-table-column
            show-overflow-tooltip
            label="券包售价"
            align="center"
            prop="packagePrice"
          />

          <el-table-column label="创建人" align="center" prop="createBy" />
          <el-table-column
            label="创建时间"
            align="center"
            prop="createTime"
            width="155"
          />

          <el-table-column label="修改人" align="center" prop="updateBy" />
          <el-table-column
            label="修改时间"
            align="center"
            prop="updateTime"
            width="155"
          />
        </el-table>
        <!-- 分页 -->
        <pagination
          v-show="total > 0"
          :total="total"
          :page.sync="queryParams.pageNum"
          :limit.sync="queryParams.pageSize"
          @pagination="getList"
        />
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="getConfirm" size="mini"
          >确 定</el-button
        >
        <el-button @click="close" size="mini">取消</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { packageListAPI } from '@/api/marketing/coupon/package' //优惠券包
export default {
  props: {
    //是否单选
    isSelectPackageRadio: {
      type: Boolean,
      default: false
    },
    //商品信息开关
    OpenPackage: {
      type: Boolean,
      default: false
    },
    //根据编号或名称筛选
    queryCode: {
      type: String
    }
  },
  data () {
    return {
      packageItem: undefined, //单选数据
      loading: false, //加载
      total: 0, //条数
      tableData: [], //优惠券包
      selectAllData: [], //选中数组
      //分页查询参数
      queryParams: {
        pageNum: 1,
        pageSize: 15,
        vipLevelName: null
      }
    }
  },

  watch: {
    OpenPackage (val) {
      if (val) {
        this.getList()
      } else {
        this.reset()
      }
    }
  },
  methods: {
    //点击行选中
    handleRowClick (row) {
      // 获取表格对象
      // 切换行选中状态
      if (this.isSelectPackageRadio) {
        this.packageItem = row
      } else {
        this.$refs.multipleTable.toggleRowSelection(row)
      }
    },
    //确定按钮
    getConfirm () {
      if (this.isSelectPackageRadio) {
        this.$emit('packageItemCom', this.packageItem)
      } else {
        if (this.selectAllData.length >= 1)
          this.$emit('packageListCom', this.selectAllData)
      }

      this.close()
    },
    //关闭对话框
    close () {
      this.$emit('update:OpenPackage', false)
    },
    // 多选框选中数据
    handleSelectionChange (selection) {
      this.selectAllData = selection
    },
    /** 查询分页列表 */
    getList () {
      this.loading = true
      packageListAPI(this.queryParams).then(response => {
        this.tableData = response.rows
        this.total = response.total
        this.loading = false
      })
    },
    /** 搜索按钮操作 */
    handleQuery () {
      this.queryParams.pageNum = 1
      this.getList()
    },
    //清空搜索框
    reset () {
      this.queryParams = {
        pageNum: 1,
        pageSize: 15
      }
    }
  }
}
</script>

<style lang="scss" scoped>
//整体盒子
.vipLevetableCom {
  display: flex;
  flex-direction: column;
  //顶部操作
  .top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
    flex-direction: row-reverse;
    //输入框
    .searchInput {
      width: 200px;
      margin-right: 5px;
    }
    ::v-deep .el-input--small .el-input__inner {
      height: 28px;
      line-height: 28px;
    }
  }
}
</style>
