<template>
  <div class="sty marB15" :style="{color: color,marginLeft: left}">{{msg}}</div>
</template>

<script>
export default {
  name: 'promptMessage',
  props:{
    msg:{
      type: String,
      default: ''
    },
    color:{
      type: String,
      default: ''
    },
    left:{
      type: String,
      default: '100px'
    }
  }
}
</script>

<style scoped>
.sty{
  font-size: 14px;
  margin-top: -20px;
}
</style>
