<template>
  <div class="search-T">
    <el-form
      ref="form"
      :model="form"
      label-width="100px"
      label-position="right"
      @submit.native.prevent
    >
      <div class="x-w selectTop">
        <slot name="selectTop" class="selectTop"></slot>
        <el-form-item label-width="10px">
          <el-button
            type="primary"
            icon="el-icon-search"
            size="mini"
            @click="searchBtn"
            >搜索
          </el-button>
        </el-form-item>
        <el-form-item label-width="10px">
          <el-button
            v-if="isHighSearch"
            :icon="icon"
            size="mini"
            @click="showHighSearch"
            >高级搜索
          </el-button>
        </el-form-item>
      </div>
      <div class="x-w HighSearch">
        <slot name="HighSearch" v-if="showHigh && isHighSearch"></slot>
      </div>
    </el-form>
  </div>
</template>

<script>
export default {
  name: 'SearchTitle',
  props: {
    isHighSearch: {
      // 是否显示高级选项按钮
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      form: {},
      icon: 'el-icon-caret-top',
      loading: false,
      showHigh: false // 是否显示高级搜索
    }
  },
  methods: {
    //是否显示高级搜索
    showHighSearch () {
      this.icon =
        this.icon === 'el-icon-caret-top'
          ? 'el-icon-caret-bottom'
          : 'el-icon-caret-top'
      this.$emit('HighSearch')
      this.showHigh = !this.showHigh
    },
    // 搜索按钮
    searchBtn () {
      this.$emit('clickSearch', '')
    }
  }
}
</script>

<style lang="scss" scoped>
//整体下边距
.search-T {
  margin-bottom: 10px;
  .el-radio-group {
    width: 630px;
  }
  .el-form-item {
    margin-bottom: 0 !important;
  }
  //高级搜索
  .HighSearch {
    .el-form-item {
      margin-top: 10px !important;
    }
  }
}
</style>
