<template>
  <div class="tableBtn">
    <div class="x-f">
      <el-button
        type="primary"
        icon="el-icon-plus"
        size="mini"
        @click="handleAdd"
        v-if="isAddBtn"
        v-hasPermi="hasPermiAddBtn"
      >
        新增
      </el-button>

      <el-dropdown
        trigger="click"
        class="marL10 marR10"
        split-button
        type="success"
        size="mini"
        icon="el-icon-circle-check"
        @click="handleAudit"
        @command="handleRedoAudit"
        v-if="isAuditBtn"
      >
        审核
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item command="反审核">反审核</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
      <el-button
        type="success"
        size="mini"
        @click="handleAudit"
        v-if="isExamineBtn"
      >
        审核
      </el-button>
      <el-dropdown
        trigger="click"
        class="marL10"
        :class="{ marR10: !isRecycleBtn }"
        split-button
        type="warning"
        size="mini"
        icon="el-icon-circle-check"
        @click="handleEnable(false)"
        @command="handleEnable(true)"
        v-if="isEnableBtn"
      >
        启用
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item command="禁用">禁用</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
      <el-button
        type="success"
        icon="el-icon-edit-outline"
        size="mini"
        @click="batchEditor"
        v-if="isBatchEditorBtn"
        >批量修改
      </el-button>
      <el-dropdown
        trigger="click"
        class="marR10"
        split-button
        type="primary"
        size="mini"
        icon="el-icon-circle-check"
        @click="handlePrint"
        @command="handleRedoPrint"
        v-if="isPrintBtn"
      >
        打印
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item command="打印">打印</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
      <!-- 特殊自定义按钮  -->
      <slot name="specialDiyBtn"></slot>
      <el-button
        type="danger"
        icon="el-icon-delete"
        @click="handleDelete"
        size="mini"
        v-if="isDeleteBtn"
        :disabled="isDeleteDisabled"
        >删除
      </el-button>
      <!-- :sunyunDropdown="{
          leftDisabled: multiple
        }" -->
      <el-dropdown
        trigger="click"
        class="marL10 marR10"
        split-button
        type="danger"
        size="mini"
        icon="el-icon-delete"
        @click="handleDelete"
        @command="handleRedoDelete"
        v-if="isRecycleBtn"
        v-hasPermi="hasPermiRecycleBtn"
      >
        删除
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item command="回收站">回收站</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>

      <el-button
        type="primary"
        icon="el-icon-remove-outline"
        size="mini"
        @click="$emit('handleClose')"
        v-if="isCloseBtn"
        >终止
      </el-button>
      <slot name="specialDiyFinalBtn"></slot>
    </div>
    <!-- 右边盒子 -->
    <div>
      <el-button
        icon="el-icon-upload2"
        size="mini"
        v-if="isImportBtn"
        @click="handleImport"
        >导入
      </el-button>
      <el-button
        icon="el-icon-download"
        size="mini"
        v-if="isExportBtn"
        @click="handleExport"
        >导出
      </el-button>
      <el-button
        size="mini"
        class="marR10"
        icon="el-icon-refresh-right"
        @click="handRefresh($event)"
        v-if="isRefreshBtn"
        >刷新
      </el-button>
      <el-button
        icon="el-icon-sort"
        size="mini"
        @click="toggleExpandAll"
        v-if="isExpandAllBtn"
        >展开/折叠
      </el-button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'operatingButton',
  props: {
    //新增按钮权限
    hasPermiAddBtn: {
      type: Array,
      default: () => []
    },
    //回收站按钮权限
    hasPermiRecycleBtn: {
      type: Array,
      default: () => []
    },
    //列表
    getList: {
      type: Function,
      default: function () {}
    },
    //回收站
    isRecycleBtn: {
      type: Boolean,
      default: false
    },
    //多选禁用
    multiple: {
      type: Boolean,
      default: true
    },
    //新增按钮
    isAddBtn: {
      type: Boolean,
      default: true
    },
    //审核/反审核按钮
    isAuditBtn: {
      type: Boolean,
      default: false
    },
    //审核按钮
    isExamineBtn: {
      type: Boolean,
      default: false
    },
    //关闭按钮
    isCloseBtn: {
      type: Boolean,
      default: false
    },
    //删除按钮
    isDeleteBtn: {
      type: Boolean,
      default: true
    },
    //刷新按钮
    isRefreshBtn: {
      type: Boolean,
      default: true
    },
    //展开/折叠
    isExpandAllBtn: {
      type: Boolean,
      default: false
    },
    //批量修改按钮
    isBatchEditorBtn: {
      type: Boolean,
      default: false
    },
    //导入按钮
    isImportBtn: {
      type: Boolean,
      default: false
    },
    //导出按钮
    isExportBtn: {
      type: Boolean,
      default: false
    },
    //特殊刷新页面
    isExceptionList: {
      type: Boolean,
      default: false
    },
    //启用禁用
    isEnableBtn: {
      type: Boolean,
      default: false
    },
    //删除是否禁用
    isDeleteDisabled: {
      type: Boolean,
      default: false
    },
    // 打印按钮
    isPrintBtn: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    //新增
    handleAdd () {
      this.$emit('handleAdd')
    },
    //批量修改
    batchEditor () {
      if (this.multiple) {
        this.$message.warning('请选择要执行的数据')
      } else {
        this.$emit('batchEditor')
      }
    },
    //删除
    handleDelete () {
      if (this.multiple) {
        this.$message.warning('请选择要执行的数据')
      } else {
        this.$emit('handleDelete')
      }
    },
    //回收站
    handleRedoDelete () {
      this.$emit('handleRedoDelete')
    },
    //刷新
    handRefresh (e) {
      this.getList()
      let target = e.target
      if (target.nodeName == 'I' || target.nodeName == 'SPAN') {
        target = e.target.parentNode
      }
      target.blur()
      // if (!this.isExceptionList) {
      //   this.getList()
      //   let target = e.target
      //   if (target.nodeName == 'I' || target.nodeName == 'SPAN') {
      //     target = e.target.parentNode
      //   }
      //   target.blur()
      // } else {
      //   this.$emit('handRefresh')
      // }

      // this.$message({
      //   message: "刷新成功",
      //   type: "success",
      // });
    },
    //导入
    handleImport () {
      this.$emit('handleImport')
    },
    //导出
    handleExport () {
      this.$emit('handleExport')
    },
    //审核
    handleAudit () {
      if (this.multiple) {
        this.$message.warning('请选择要执行的数据')
      } else {
        this.$emit('handleAudit')
      }
    },
    //反审核
    handleRedoAudit () {
      if (this.multiple) {
        this.$message.warning('请选择要执行的数据')
      } else {
        this.$emit('handleRedoAudit')
      }
    },
    //启用
    handleEnable (Boole) {
      if (this.multiple) {
        this.$message.warning('请选择要执行的数据')
      } else {
        this.$emit('handleEnable', Boole)
      }
    },
    toggleExpandAll () {
      this.$emit('toggleExpandAll')
    },
    // 打印
    handlePrint () {
      if (this.multiple) {
        this.$message.warning('请选择要执行的数据')
      } else {
        this.$emit('handlePrint')
      }
    },
    handleRedoPrint() {
      if (this.multiple) {
        this.$message.warning('请选择要执行的数据')
      } else {
        this.$emit('handleRedoPrint')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.tableBtn {
  height: 50px;
  line-height: 50px;
  background-color: #f9f9fa;
  padding-left: 10px;
  margin-bottom: 6px;
  display: flex;
  justify-content: space-between;
}
</style>
