<template>
  <div>
    <el-dialog
      :visible="OpenBrand"
      title="选择品牌"
      :before-close="close"
      v-dialogDrag="true"
      width="1100px"
    >
      <!-- 整个表格 -->
      <div class="tableBody" v-loading="loading">
        <div class="top">
          <!-- 搜索框 -->
          <div class="search">
            <el-input
              class="searchInput"
              placeholder="请输入品牌编号"
              prefix-icon="el-icon-search"
              v-model="queryParams.brandNo"
              size="small"
              @keyup.enter.native="handleQuery"
            >
            </el-input>
            <el-button
              type="primary"
              icon="el-icon-search"
              size="mini"
              @click="handleQuery"
              >搜索</el-button
            >
          </div>
        </div>
        <el-table
          :data="tableData"
          tooltip-effect="dark"
          style="width: 100%"
          @selection-change="handleSelectionChange"
          @row-click="handleRowClick"
          border
          max-height="400"
          ref="multipleTable"
        >
          <el-table-column align="center" type="selection" width="55">
          </el-table-column>
          <el-table-column align="center" type="index" width="80" label="序号">
          </el-table-column>
          <el-table-column align="center" prop="brandNo" label="品牌编号">
          </el-table-column>
          <el-table-column prop="brandName" label="品牌名称" align="center">
          </el-table-column>
          <el-table-column
            align="center"
            prop="remark"
            label="备注"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            align="center"
            prop="createBy"
            label="创建人"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            prop="createTime"
            label="创建时间"
            show-overflow-tooltip
            width="155"
          >
          </el-table-column>
          <el-table-column
            align="center"
            prop="updateBy"
            label="修改人"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            prop="updateTime"
            label="修改时间"
            show-overflow-tooltip
            width="155"
          >
          </el-table-column>
        </el-table>
        <!-- 分页 -->
        <pagination
          v-show="total > 0"
          :total="total"
          :page.sync="queryParams.pageNum"
          :limit.sync="queryParams.pageSize"
          @pagination="getList"
        />
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="close" size="mini">取 消</el-button>
        <el-button type="primary" @click="getConfirm" size="mini">{{
          confirmText
        }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { listBrand } from '@/api/goods/brand' //品牌
export default {
  props: {
    //确定按钮
    confirmText: {
      type: String,
      default: '确 定'
    },
    //品牌信息开关
    OpenBrand: {
      type: Boolean,
      default: false
    },
    //重置搜索条件
    refreshSearch: {
      type: Object,
      default: function () {
        return {
          pageNum: 1,
          pageSize: 15
        }
      }
    }
  },
  data () {
    return {
      loading: false, //加载
      total: 0, //条数
      tableData: [], //会员等级
      selectAllData: [], //选中数组
      //分页查询参数
      queryParams: {
        pageNum: 1,
        pageSize: 15
      }
    }
  },

  watch: {
    OpenBrand (val) {
      if (val) {
        this.reset()
        this.getList()
      }
    }
  },
  methods: {
    //点击选中
    handleRowClick (row) {
      // 获取表格对象
      // 切换行选中状态
      this.$refs.multipleTable.toggleRowSelection(row)
    },
    //确定按钮
    getConfirm () {
      if (this.selectAllData.length >= 1)
        this.$emit('brandList', this.selectAllData)

      this.close()
    },
    //关闭对话框
    close () {
      this.$emit('update:OpenBrand', false)
    },
    // 多选框选中数据
    handleSelectionChange (selection) {
      this.selectAllData = selection
    },
    /** 查询分页列表 */
    getList () {
      this.loading = true
      listBrand(this.queryParams).then(response => {
        this.tableData = response.rows
        this.total = response.total
        this.loading = false
      })
    },
    /** 搜索按钮操作 */
    handleQuery () {
      this.queryParams.pageNum = 1
      this.getList()
    },
    //清空搜索框
    reset () {
      //清空查询参数
      this.queryParams = JSON.parse(JSON.stringify(this.refreshSearch))
    }
  }
}
</script>

<style lang="scss" scoped>
//输入框
.vipInput {
  width: 89%;
}
.searchInput {
  width: 68%;
  margin-right: 2%;
}
//整体盒子

.tableBody {
  padding: 30px;
  min-width: 0;
  display: flex;
  flex-direction: column;
}

//顶部操作
.top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  flex-direction: row-reverse;
}
//输入框高度
::v-deep .el-input--small .el-input__inner {
  height: 28px;
  line-height: 28px;
}
//处理默认对话框的样式
::v-deep .el-dialog__body {
  padding: 0;
}
</style>
