<template>
  <div class="wrap">
    <TablePage ref="tablePage" v-model="options" @handleEvent="handleEvent">
    </TablePage>
  </div>
</template>
<script>
import TablePage from '@/components/tablePage' //表格页面组件
import {
  listInferior,
  processInferiorToStoreLoss,
  processInferiorToStoreAllot
} from "@/api/produce/bill/scrapDeal"; //产品次品
import json from 'highlight.js/lib/languages/json';
export default {
  name: 'scrapDeal',
  components: {
    TablePage
  },
  data() {
    return {
      options: {
        loading: false,
        pagination: {
          total: 0,
          page: 1,
          size: 15
        },
        title: '相关单据',
        listNo: true, // 序号
        rowKey: 'billDetailId',
        // defaultBody: { inferiorType: 1 },
        // getListApi: listInferior,
        pagination: {
          onlySelectTotal: true
        },
        getDataKey: { list: "data" },
        buttons: [
          {
            click: 'withdraw',
            noRule: true,
            label: '返回',
            type: 'primary'
          },
        ],
        columns: [
          {
            prop: "billNo",
            label: "单据号",
            minWidth: 180,
          },
          {
            prop: "billDate",
            label: "单据日期",
            minWidth: 180,
          },
          {
            prop: "billTypeName",
            label: "单据类型",
            minWidth: 120,
          },
          {
            prop: "billStatusName",
            label: "单据状态",
            minWidth: 120,
          },
          {
            prop: "billMoney",
            label: "单据金额",
            minWidth: 120,
          },
          {
            prop: "storeName",
            label: "仓库名称",
            minWidth: 120,
          },
          {
            prop: "remark",
            label: "备注",
            minWidth: 120,
          }
        ],
        summary: ['billMoney']
      }
    }
  },
  beforeRouteEnter(to, from, next) {
    next(async (vm) => {
      try {
        vm.options.loading = true;
        // 访问组件实例 vm 中的数据和方法
        if (vm.$route.query.type) {
          vm.type = vm.$route.query.type
          vm.options.list = JSON.parse(vm.$route.query.data)
          vm.options.pagination.total = vm.options.list.length
        }
      } finally {
        vm.options.loading = false;
      }
    });
  },
  methods: {
    async handleEvent(type, row) {
      switch (type) {
        case 'withdraw':
          // 创建一个映射对象，将type值映射到对应的路径
          const pathMap = {
            'producePlan': '/produce/bill/plan',
            'produceLeadMaterial': '/produce/bill/leadMaterial',
            'produceMatchMaterial': '/produce/bill/matchMaterial',
            'produceInStore': '/produce/bill/inStore',
            'produceStoreDistribute': '/produce/bill/storeDistribute',
          };

          // 检查this.type是否在映射中存在
          if (pathMap[this.type]) {
            // 如果存在，则关闭当前tab页签，打开新页签
            this.$tab.closeOpenPage({ path: pathMap[this.type] });
          }
          break
        default:
          break
      }
    }
  }
}
</script>
