<template>
  <div>
    <!-- 选择供应商 -->
    <el-dialog
      title="选择供应商"
      :visible.sync="openSelectSupplierM"
      :before-close="close"
      width="70%"
      v-dialogDrag="true"
    >
      <div class="theBody" v-loading="loading">
        <!-- 左边树装选择 -->
        <div>
          <el-tabs
            v-model="activeName"
            type="card"
            :stretch="true"
            @tab-click="handleClick"
          >
            <el-tab-pane label="区域" name="first">
              <el-input
                class="searchTree"
                placeholder="输入关键字进行过滤"
                v-model="areaFilterNode"
                size="small"
                prefix-icon="el-icon-search"
                style="margin-bottom: 10px"
              >
              </el-input>
              <el-tree
                :filter-node-method="filterNode"
                :data="areaTree"
                node-key="id"
                :props="defaultProps"
                :default-expand-all="isExpand"
                ref="areaTree"
                @node-click="handleNodeClick"
                :expand-on-click-node="false"
              >
              </el-tree
            ></el-tab-pane>
            <el-tab-pane label="分组" name="second">
              <el-input
                class="searchTree"
                placeholder="输入关键字进行过滤"
                v-model="groupFilterNode"
                size="small"
                prefix-icon="el-icon-search"
                style="margin-bottom: 10px"
              >
              </el-input>
              <el-tree
                :filter-node-method="filterNode"
                :data="groupTree"
                node-key="id"
                :props="defaultProps"
                :default-expand-all="isExpand"
                ref="groupTree"
                @node-click="handleNodeClick"
                :expand-on-click-node="false"
              >
              </el-tree>
            </el-tab-pane>
          </el-tabs>
        </div>
        <!-- 右边表格 -->
        <div class="tableBody">
          <div class="top">
            <!-- 搜索框 -->
            <div class="search">
              <el-input
                placeholder="请输入供应商名称或供应商编码"
                prefix-icon="el-icon-search"
                v-model="queryParams.paramName"
                size="small"
              >
              </el-input>
              <el-button
                type="primary"
                icon="el-icon-search"
                size="small"
                @click="handleQuery"
                >搜索</el-button
              >
            </div>
          </div>
          <el-table
            ref="multipleTable"
            :data="tableData"
            border
            v-loading="loadingTable"
            @selection-change="handleSelectionChange"
            height="500"
          >
            <el-table-column width="55" align="center" type="selection" />
            <el-table-column
              align="center"
              type="index"
              label="序号"
              width="80"
            >
            </el-table-column>
            <el-table-column
              align="center"
              prop="partnerNo"
              label="供应商编码"
              show-overflow-tooltip
              width="120"
            >
              <template slot-scope="scope">
                <el-link
                  type="primary"
                  @click="handleDetail(scope.row)"
                  target="_blank"
                >
                  {{ scope.row.partnerNo }}
                </el-link>
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              prop="partnerName"
              label="供应商名称"
              show-overflow-tooltip
              width="120"
            >
            </el-table-column>

            <el-table-column label="客户类型" align="center" prop="partnerType">
              <template slot-scope="scope">
                <dict-tag
                  :options="dict.type.partner_type"
                  :value="scope.row.partnerType"
                />
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              prop="areaName"
              label="供应商区域"
              show-overflow-tooltip
              width="120"
            >
            </el-table-column>
            <el-table-column
              align="center"
              prop="groupName"
              label="供应商组别"
              show-overflow-tooltip
              width="120"
            >
            </el-table-column>
            <el-table-column label="结算方式" align="center" prop="payType">
              <template slot-scope="scope">
                <dict-tag
                  :options="dict.type.pay_type"
                  :value="scope.row.payType"
                />
              </template>
            </el-table-column>
            <el-table-column label="状态" align="center" prop="status">
              <template slot-scope="scope">
                <dict-tag
                  :options="dict.type.common_status"
                  :value="scope.row.status"
                />
              </template>
            </el-table-column>
            <el-table-column label="删除状态" align="center" prop="delFlag">
              <template slot-scope="scope">
                <dict-tag
                  :options="dict.type.App_del_flag"
                  :value="scope.row.delFlag"
                />
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              prop="invoiceDepositBank"
              label="开户银行"
              show-overflow-tooltip
              width="120"
            >
            </el-table-column>
            <el-table-column
              align="center"
              prop="invoiceBankName"
              label="开户名称"
              show-overflow-tooltip
              width="120"
            >
            </el-table-column
            ><el-table-column
              align="center"
              prop="invoiceBankNo"
              label="银行账户"
              show-overflow-tooltip
              width="120"
            >
            </el-table-column>
            <el-table-column
              prop="linkMan"
              label="联系人"
              show-overflow-tooltip
              width="120"
              align="center"
              :formatter="linkMan"
            >
            </el-table-column
            ><el-table-column
              prop="linkManTel"
              label="联系电话"
              show-overflow-tooltip
              width="120"
              align="center"
              :formatter="linkManTel"
            >
            </el-table-column
            ><el-table-column
              align="center"
              prop="address"
              label="地址"
              show-overflow-tooltip
              width="120"
            >
            </el-table-column
            ><el-table-column
              align="center"
              prop="taxRate"
              label="税率"
              show-overflow-tooltip
              width="120"
            >
            </el-table-column
            ><el-table-column
              align="center"
              prop="invoiceTitle"
              label="发票抬头"
              show-overflow-tooltip
              width="120"
            >
            </el-table-column
            ><el-table-column
              align="center"
              prop="invoiceLicenseNo"
              label="纳税人识别号"
              show-overflow-tooltip
              width="120"
            >
            </el-table-column
            ><el-table-column
              align="center"
              prop="creditAmount"
              label="授信额度"
              show-overflow-tooltip
              width="120"
            >
            </el-table-column
            ><el-table-column
              align="center"
              prop="usedAmount"
              label="已用额度"
              show-overflow-tooltip
              width="120"
            >
            </el-table-column>
            <el-table-column
              align="center"
              prop="usableAmount"
              label="剩余额度"
              show-overflow-tooltip
              width="120"
            >
            </el-table-column>
            <el-table-column
              prop="remark"
              label="备注"
              show-overflow-tooltip
              width="120"
              align="center"
              :formatter="remark"
            >
            </el-table-column
            ><el-table-column
              align="center"
              prop="postalCode"
              label="邮编"
              show-overflow-tooltip
              width="120"
            >
            </el-table-column
            ><el-table-column
              align="center"
              prop="createBy"
              label="创建人"
              show-overflow-tooltip
              width="120"
            >
            </el-table-column
            ><el-table-column
              align="center"
              prop="createTime"
              label="创建时间"
              show-overflow-tooltip
              width="155"
            >
            </el-table-column
            ><el-table-column
              align="center"
              prop="updateBy"
              label="修改人"
              show-overflow-tooltip
              width="120"
            >
            </el-table-column>
            <el-table-column
              align="center"
              prop="updateTime"
              label="修改时间"
              show-overflow-tooltip
              width="155"
            >
            </el-table-column>
          </el-table>
          <!-- 分页 -->
          <pagination
            v-show="total > 0"
            :total="total"
            :page.sync="queryParams.pageNum"
            :limit.sync="queryParams.pageSize"
            @pagination="getList"
          />
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="onSubmitSupplier">确 定</el-button>
        <el-button @click="close">取 消</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import { listSupplier } from '@/api/goods/supplier.js'
import { getAreaTree } from '@/api/goods/area.js' //客供区域
import { listTree } from '@/api/goods/group' //客供分组
export default {
  name: 'selectSupplier',
  dicts: ['partner_type', 'App_del_flag', 'common_status', 'pay_type'],
  components: { Treeselect },
  props: {
    openSelectSupplierM: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      areaFilterNode: '', //区域搜索框
      groupFilterNode: '', //分组搜索框
      loadingTable: false, //表格加载
      loading: false, //页面加载
      isExpand: true, //是否默认展开
      single: true, //编辑和的禁用
      multiple: true, //删除和批量修改
      total: 0, //分页总条数
      activeName: 'first', //默认显示哪一个tab
      areaTree: [], //区域tree
      groupTree: [], //分组tree
      selectionSupplierCom: [], //选中仓库名称
      nodeId: null, //点击的节点id
      labelTab: '区域',
      formLabelWidth: '100px',
      defaultProps: {
        //tree结构
        children: 'children',
        label: 'label'
      },
      //   右边表格
      tableData: [
        {
          partnerName: null, //客供名称
          partnerNo: null, //客供编码
          partnerType: null, //客供类型（0 客供 1 客户 2 供应商 3 伙伴 ）
          payType: null, //付款方式（0 现金 1 支票 2 银行帐户）
          address: null, //地址
          taxRate: null, //税率
          invoiceDepositBank: null, //开户银行
          invoiceBankName: null, //银行账户名称
          invoiceLicenseNo: null, //发票纳税人识别号
          creditAmount: null, //信用额度
          usedAmount: null, //已用额度
          remark: null, //备注
          postalCode: null, //邮编
          createBy: null, //创建人
          createTime: null, //创建时间
          updateBy: null, //修改人
          updateTime: null, //修改时间
          consigneeMan: null, //联系人
          consigneeManTel: null, //联系电话
          areaName: null, //客户区域
          groupName: null //组别名称
        }
      ],
      //分页查询参数
      queryParams: {
        pageNum: 1,
        pageSize: 15
      }
    }
  },

  created () {
    this.getRender()
  },
  watch: {
    areaFilterNode (val) {
      this.$refs.areaTree.filter(val)
    },
    groupFilterNode (val) {
      this.$refs.groupTree.filter(val)
    }
  },
  methods: {
    // 多选框选中数据
    handleSelectionChange (selection) {
      this.selectionSupplierCom = selection
    },
    //关闭选择供应商对话框
    close () {
      this.$emit('update:openSelectSupplierM', false)
    },
    //树搜索框过滤方法
    filterNode (value, data) {
      if (!value) return true
      return data.label.indexOf(value) !== -1
    },
    //tabs点击事件
    handleClick (tab) {
      this.labelTab = tab.label
    },
    // 节点单击事件
    handleNodeClick (data) {
      this.queryParams = {
        pageNum: 1,
        pageSize: 15,
        areaId: null, // 片区id
        groupId: null, //组别ID
        paramName: null //名称、编码
      }
      if (this.labelTab == '区域') {
        this.queryParams.areaId = data.id
      } else {
        this.queryParams.groupId = data.id
      }
      this.handleQuery()
    },
    //下拉树结构数据转换
    normalizer (node) {
      return {
        id: node.id,
        label: node.label,
        children: node.children
      }
    },
    //渲染事件
    async getRender () {
      this.loading = true
      this.getTreeselect()
      this.getList()
      this.loading = false
    },
    //获取树
    async getTreeselect () {
      const res1 = await getAreaTree() //获取树数据
      this.areaTree = res1.data //赋值给tree
      const res2 = await listTree()
      this.groupTree = res2.data
    },
    /** 查询分页列表 */
    getList () {
      this.loadingTable = true
      listSupplier(this.queryParams).then(response => {
        this.tableData = response.rows
        this.total = response.total
      })
      this.loadingTable = false
    },
    /** 搜索按钮操作 */
    handleQuery () {
      this.queryParams.pageNum = 1
      this.getList()
    },
    //联系人
    linkMan (row) {
      if (row.linkmanList) {
        if (row.linkmanList[0].linkMan) {
          return row.linkmanList[0].linkMan
        }
      } else {
        return ''
      }
    },
    //联系电话
    linkManTel (row) {
      if (row.linkmanList) {
        if (row.linkmanList[0].linkManTel) {
          return row.linkmanList[0].linkManTel
        }
      } else {
        return ''
      }
    },
    //备注
    remark (row) {
      if (row.linkmanList) {
        if (row.linkmanList[0].remark) {
          return row.linkmanList[0].remark
        }
      } else {
        return ''
      }
    },
    //传送获取选中的供应商id
    onSubmitSupplier () {
      if (this.selectionSupplierCom.length > 0)
        this.$emit('selectionSupplierCom', this.selectionSupplierCom)
      this.close()
    }
  }
}
</script>

<style lang="scss" scoped>
//搜索框
.el-input {
  width: 280px;
  margin-right: 8px;
}
//整体盒子
.theBody {
  display: flex;
  padding: 20px;
  height: 600px;
  .el-tabs {
    width: 300px;
    margin-right: 20px;
  }
  .tableBody {
    min-width: 0;
    display: flex;
    flex-direction: column;
  }
}
//顶部操作
.top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}
::v-deep .vue-treeselect__control {
  width: 280px;
} //treeselect
::v-deep .vue-treeselect__menu-container {
  width: 280px;
} //treeselect
.batch {
  width: 300px;
}
//搜索tree
.searchTree {
  width: 300px;
}
</style>
