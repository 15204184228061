<template>
  <div class="search-box">
    <el-input
      size="mini"
      class="inputSearch"
      type="text"
      v-model="searchText"
      @input="handleInput"
      @blur="handleBlur"
      @keydown.down="handleKeyDown"
      @keydown.up="handleKeyDown"
      :placeholder="placeholderText"
      @keyup.enter.native="handleEnter"
    >
      <i slot="prefix" class="el-input__icon el-icon-search"></i>
    </el-input>
    <ul v-show="showDropDown" ref="clickLiList">
      <li
        class="fontS14"
        v-for="(item, index) in dropDownList"
        :key="index"
        :class="{ active: index === activeIndex }"
        @click="handleItemClick(index)"
      >
        {{ item.name }}
        <span class="marR10">:</span>
        <span class="fontS14">{{ searchText }}</span>
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  props: {
    //单据日期
    dropDownList: {
      type: Array,
      default: function () {
        return [{ parameter: 'query', name: '全部' }]
      }
    },
    //提示内容
    placeholderText: {
      type: String || Number,
      default: ''
    }
  },
  watch: {
    //输入框输入
    searchText (newVal, oldVal) {
      this.$emit('searchTextInput', this.searchText)
    }
  },
  data () {
    return {
      //判断是否点击的是li
      isLiDom: false,
      searchText: '',
      showDropDown: false,
      activeIndex: -1
    }
  },
  methods: {
    resetInputText () {
      this.searchText = undefined
    },
    handleInput () {
      if (!this.showDropDown) {
        this.showDropDown = true
      }
      if (this.searchText === '') {
        this.showDropDown = false
        return
      }
      //   if (/^\d+$/.test(this.searchText)) {
      //     this.dropDownList[0] = '搜索编号'
      //   } else {
      //     this.dropDownList[0] = '搜索名称'
      //   }
      this.activeIndex = -1
    },
    handleBlur () {
      // 获取DOM元素
      const listElem = this.$refs.clickLiList
      // 绑定事件监听器
      //   console.time()
      listElem.addEventListener('mousedown', event => {
        const target = event.target
        if (target.tagName.toLowerCase() === 'li') {
          this.isLiDom = true
        }
      })
      //   console.timeEnd()
      setTimeout(() => {
        //不是li元素关闭弹窗
        if (!this.isLiDom) {
          this.showDropDown = false
        } else {
          this.isLiDom = false
        }
      }, 300)
    },
    //点击事件
    handleItemClick (index) {
      //获取参数名称和输入值
      this.$emit('parameter', {
        [this.dropDownList[index].parameter]: this.searchText
      })
      // if (index === 0) {
      //   // 搜索名称
      //   console.log('搜索名称：', this.searchText)
      // } else {
      //   // 搜索编号
      //   console.log('搜索编号：', this.searchText)
      // }
      this.showDropDown = false
    },
    //回车事件
    handleEnter () {
      this.$emit('searchInputEnter', this.searchText)
      this.showDropDown = false
    },
    handleKeyDown (event) {
      if (event.keyCode === 38) {
        // up arrow key
        event.preventDefault()
        if (this.activeIndex > 0) {
          this.activeIndex--
        }
      } else if (event.keyCode === 40) {
        // down arrow key
        event.preventDefault()
        if (this.activeIndex < this.dropDownList.length - 1) {
          this.activeIndex++
        }
      } else if (event.keyCode === 13) {
        // enter key
        event.preventDefault()
        if (this.activeIndex >= 0) {
          this.handleItemClick(this.activeIndex)
        }
      }
    },
    reset () {
      this.searchText = undefined
    }
  }
}
</script>
<style lang="scss" scoped>
.search-box {
  width: 200px;
  //   position: relative;
  //搜索框
  .inputSearch {
    width: 200px;
  }
  ul {
    width: 200px;
    margin: 0;
    padding: 0;
    list-style: none;
    max-height: 250px;
    overflow-y: auto;
    overflow-x: hidden;
    position: fixed;
    z-index: 2005;
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.2);
    background-color: #fff;
  }

  li {
    width: 200px;
    padding: 5px;
    cursor: pointer;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  li.active {
    background-color: #eee;
  }
  li:hover {
    background: #f2f2f2;
  }
  li:nth-child(1) {
    background: #e9f2fd;
    color: #2386ee;
  }
}
</style>
