var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      directives: [
        {
          name: "dialogDrag",
          rawName: "v-dialogDrag",
          value: true,
          expression: "true",
        },
      ],
      attrs: {
        visible: _vm.openInStore,
        title: "商品详情",
        "before-close": _vm.close,
        width: "60%",
      },
    },
    [
      _c(
        "el-table",
        {
          attrs: {
            "tooltip-effect": "dark",
            data: _vm.inStoreForm.details,
            border: "",
            "max-height": "500",
            height: "500",
          },
        },
        [
          _c("el-table-column", {
            attrs: { type: "selection", width: "50", align: "center" },
          }),
          _c("el-table-column", {
            attrs: {
              label: "序号",
              align: "center",
              prop: "userId",
              width: "80",
              type: "index",
            },
          }),
          _c("el-table-column", {
            attrs: {
              "show-overflow-tooltip": "",
              label: "商品编码",
              align: "center",
              prop: "goodsNo",
              width: "180",
            },
          }),
          _c("el-table-column", {
            attrs: {
              label: "商品名称",
              align: "center",
              prop: "goodsName",
              width: "155",
            },
          }),
          _c("el-table-column", {
            attrs: {
              label: "商品条码",
              align: "center",
              prop: "barcode",
              width: "180",
            },
          }),
          _c("el-table-column", {
            attrs: {
              label: "规格",
              align: "center",
              prop: "goodsSpec",
              width: "150",
            },
          }),
          _c("el-table-column", {
            attrs: {
              label: "单位",
              align: "center",
              prop: "unitName",
              width: "100",
            },
          }),
          _c("el-table-column", {
            attrs: {
              label: "生产批号",
              align: "center",
              prop: "batchNo",
              width: "180",
            },
          }),
          _c("el-table-column", {
            attrs: {
              label: "生产计划数",
              align: "center",
              prop: "importUnitQty",
              width: "100",
            },
          }),
          _c("el-table-column", {
            attrs: {
              label: "入库数量",
              align: "center",
              prop: "unitProduceInferiorQty",
              width: "130",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("el-input", {
                      attrs: { disabled: _vm.iSBeforeinStorage },
                      nativeOn: {
                        keyup: function ($event) {
                          return _vm.onkeyupUnitQty(scope.row)
                        },
                      },
                      model: {
                        value: scope.row.unitQty,
                        callback: function ($$v) {
                          _vm.$set(scope.row, "unitQty", $$v)
                        },
                        expression: "scope.row.unitQty",
                      },
                    }),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              label: "合格数量",
              align: "center",
              prop: "unitEligibleQty",
              width: "100",
            },
          }),
          _c("el-table-column", {
            attrs: {
              label: "生产次品",
              align: "center",
              prop: "unitProduceInferiorQty",
              width: "130",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("el-input", {
                      attrs: { disabled: _vm.productionQuality },
                      nativeOn: {
                        keyup: function ($event) {
                          return _vm.onkeyupUnitQty(scope.row)
                        },
                      },
                      model: {
                        value: scope.row.unitProduceInferiorQty,
                        callback: function ($$v) {
                          _vm.$set(scope.row, "unitProduceInferiorQty", $$v)
                        },
                        expression: "scope.row.unitProduceInferiorQty",
                      },
                    }),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              label: "包装次品",
              align: "center",
              prop: "unitPackInferiorQty",
              width: "130",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("el-input", {
                      attrs: { disabled: _vm.completeQuality },
                      nativeOn: {
                        keyup: function ($event) {
                          return _vm.onkeyupUnitQty(scope.row)
                        },
                      },
                      model: {
                        value: scope.row.unitPackInferiorQty,
                        callback: function ($$v) {
                          _vm.$set(scope.row, "unitPackInferiorQty", $$v)
                        },
                        expression: "scope.row.unitPackInferiorQty",
                      },
                    }),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.getInStore } },
            [_vm._v("确 定")]
          ),
          _c("el-button", { on: { click: _vm.close } }, [_vm._v("取消")]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }