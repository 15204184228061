<template>
  <el-dialog
    :visible="openInStore"
    title="商品详情"
    :before-close="close"
    v-dialogDrag="true"
    width="60%"
  >
    <el-table
      tooltip-effect="dark"
      :data="inStoreForm.details"
      border
      max-height="500"
      height="500"
    >
      <el-table-column type="selection" width="50" align="center" />
      <el-table-column
        label="序号"
        align="center"
        prop="userId"
        width="80"
        type="index"
      />
      <el-table-column
        show-overflow-tooltip
        label="商品编码"
        align="center"
        prop="goodsNo"
        width="180"
      >
      </el-table-column>
      <el-table-column
        label="商品名称"
        align="center"
        prop="goodsName"
        width="155"
      />
      <el-table-column
        label="商品条码"
        align="center"
        prop="barcode"
        width="180"
      />
      <el-table-column
        label="规格"
        align="center"
        prop="goodsSpec"
        width="150"
      />
      <el-table-column
        label="单位"
        align="center"
        prop="unitName"
        width="100"
      />
      <el-table-column
        label="生产批号"
        align="center"
        prop="batchNo"
        width="180"
      />
      <el-table-column
        label="生产计划数"
        align="center"
        prop="importUnitQty"
        width="100"
      />
      <el-table-column
        label="入库数量"
        align="center"
        prop="unitProduceInferiorQty"
        width="130"
      >
        <template slot-scope="scope">
          <el-input
            :disabled="iSBeforeinStorage"
            @keyup.native="onkeyupUnitQty(scope.row)"
            v-model="scope.row.unitQty"
          ></el-input>
        </template>
      </el-table-column>
      <el-table-column
        label="合格数量"
        align="center"
        prop="unitEligibleQty"
        width="100"
      />
      <el-table-column
        label="生产次品"
        align="center"
        prop="unitProduceInferiorQty"
        width="130"
      >
        <template slot-scope="scope">
          <el-input
            :disabled="productionQuality"
            @keyup.native="onkeyupUnitQty(scope.row)"
            v-model="scope.row.unitProduceInferiorQty"
          ></el-input>
        </template>
      </el-table-column>
      <el-table-column
        label="包装次品"
        align="center"
        prop="unitPackInferiorQty"
        width="130"
      >
        <template slot-scope="scope">
          <el-input
            :disabled="completeQuality"
            @keyup.native="onkeyupUnitQty(scope.row)"
            v-model="scope.row.unitPackInferiorQty"
          ></el-input>
        </template>
      </el-table-column>
    </el-table>

    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="getInStore">确 定</el-button>
      <el-button @click="close">取消</el-button>
    </span>
  </el-dialog>
</template>
<script>
import {
  inStoreUpdateAPI,
  updateProductCheckStatusAPI,
  updateQualityCheckStatusAPI,
  updateInStoreStatusAPI
} from '@/api/produce/bill/inStore' //产品入库
export default {
  name: 'selectProductStorage',
  data () {
    return {
      productionQuality: false, //生产部质检
      completeQuality: false, //成品部质检
      iSBeforeinStorage: false //已入库数量
    }
  },
  props: {
    //信息表格
    inStoreForm: {
      type: Object,
      default: function () {
        return { details: [] }
      }
    },
    //商品信息开关
    openInStore: {
      type: Boolean,
      default: false
    },
    //仓库
    iSOpenStorage: {
      type: Number,
      default: undefined
    }
  },
  watch: {
    //商品信息开关
    openInStore (val) {
      if (val) {
        // this.getList()
        // console.log(this.inStoreForm)
      } else {
        this.reset()
      }
    },
    //仓库
    iSOpenStorage (val) {
      console.log('val', val)
      if (val == 1) {
        //生产部质检
        this.productionQuality = false
        //成品部质检
        this.completeQuality = true
        //已入库数量
        this.iSBeforeinStorage = true
      } else if (val == 2) {
        //生产部质检
        this.productionQuality = true
        //成品部质检
        this.completeQuality = false
        //已入库数量
        this.iSBeforeinStorage = true
      } else if (val == 3) {
        //生产部质检
        this.productionQuality = true
        //成品部质检
        this.completeQuality = true
        //已入库数量
        this.iSBeforeinStorage = false
      }
    }
  },
  methods: {
    reset () {
      //生产部质检
      this.productionQuality = true
      //成品部质检
      this.completeQuality = true
      //已入库数量
      this.iSBeforeinStorage = true
      //三个状态控制的字段
      this.iSOpenStorage = 0
    },
    //领用数量
    onkeyupUnitQty (row) {
      if (
        (row.unitProduceInferiorQty || row.unitPackInferiorQty) &&
        row.unitQty
      ) {
        if (!row.unitProduceInferiorQty) {
          row.unitProduceInferiorQty = ''
        }
        if (!row.unitPackInferiorQty) {
          row.unitPackInferiorQty = ''
        }
        let unitEligibleQty =
          row.unitQty - row.unitProduceInferiorQty - row.unitPackInferiorQty
        unitEligibleQty = this.formatNumber(unitEligibleQty)
        this.$set(row, 'unitEligibleQty', unitEligibleQty)
      } else {
        this.$set(row, 'unitEligibleQty', 0)
      }
    },

    //关闭对话框
    close () {
      this.$emit('update:openInStore', false)
    },
    //选中商品
    async getInStore () {
      await inStoreUpdateAPI(this.inStoreForm)
      if (this.iSOpenStorage == 1) {
        await updateProductCheckStatusAPI({
          billIds: [this.inStoreForm.billId],
          productCheckStatus: 3
        })
        this.$message({
          message: '修改生产部质检状态成功',
          type: 'success'
        })
      } else if (this.iSOpenStorage == 2) {
        await updateQualityCheckStatusAPI({
          billIds: [this.inStoreForm.billId],
          qualityCheckStatus: 3
        })
        this.$message({
          message: '修改成品部质检状态成功',
          type: 'success'
        })
      } else if (this.iSOpenStorage == 3) {
        await updateInStoreStatusAPI({
          billIds: [this.inStoreForm.billId],
          inStoreStatus: 3
        })
        this.$message({
          message: '修改入库状态成功',
          type: 'success'
        })
      }
      this.close()
      this.$parent.getList()
    }
  }
}
</script>
