div
<template>
  <div class="select-goods">
    <el-dialog
      :visible="openCustomerCards"
      title="选择客户"
      :before-close="close"
      v-dialogDrag="true"
      width="70%"
    >
      <div class="app-container" v-loading="loading">
        <el-row class="main">
          <!--分类数据-->
          <el-col :span="4" :xs="24">
            <div class="head-container">
              <el-input
                v-model="categoryName"
                placeholder="输入客户分组"
                clearable
                size="small"
                prefix-icon="el-icon-search"
                style="margin-bottom: 20px"
              />
            </div>
            <div class="head-container">
              <!-- 分类树 -->
              <el-scrollbar class="scrollbarTree">
                <el-tree
                  node-key="id"
                  :data="customerTreeOptions"
                  :props="defaultProps"
                  :expand-on-click-node="false"
                  :filter-node-method="filterNode"
                  ref="tree"
                  :default-expand-all="true"
                  :highlight-current="true"
                  @node-click="handleNodeClick"
                />
              </el-scrollbar>
            </div>
          </el-col>
          <!--类别数据-->
          <el-col :span="19" :xs="24">
            <el-row :gutter="10">
              <el-col :span="18">
                <el-form
                  :model="queryParams"
                  ref="queryForm"
                  size="small"
                  :inline="true"
                  v-show="showSearch"
                  label-width="68px"
                  @submit.native.prevent
                >
                  <el-form-item label="" prop="categoryName">
                    <el-input
                      v-model="queryParams.paramName"
                      placeholder="输入客户名称\编码"
                      clearable
                      style="width: 240px"
                      @keyup.enter.native="handleQuery"
                    />
                  </el-form-item>
                  <el-form-item>
                    <el-button
                      type="primary"
                      icon="el-icon-search"
                      size="mini"
                      @click="handleQuery"
                      >搜索
                    </el-button>
                  </el-form-item>
                </el-form>
              </el-col>
            </el-row>
            <!-- 商品数据表格 -->
            <div class="goodsData marT10">
              <el-table
                :default-sort="{ prop: 'date', order: 'descending' }"
                :data="customerSList"
                @selection-change="handleSelectionChange"
                border
                height="500"
                class="table-goods"
                ref="customerTable"
                row-key="partnerId"
                @select-all="tabSelectAll"
                @select="tabSelect"
                @row-click="handleRowClick"
              >
                <el-table-column
                  type="selection"
                  width="50"
                  align="center"
                  :reserve-selection="true"
                />
                <el-table-column
                  label="序号"
                  type="index"
                  width="60"
                  align="center"
                  key="sortNo"
                  prop="sortNo"
                />
                <el-table-column
                  prop="partnerNo"
                  label="客户编码"
                  show-overflow-tooltip
                  width="120"
                  align="center"
                />
                <el-table-column
                  prop="partnerName"
                  label="客户名称"
                  show-overflow-tooltip
                  align="center"
                />
                <el-table-column
                  prop="groupName"
                  label="客户组别"
                  show-overflow-tooltip
                  align="center"
                  key="linkManTel"
                />
                <el-table-column
                  label="手机号"
                  align="center"
                  prop="linkManTel"
                  :show-overflow-tooltip="true"
                />
              </el-table>
              <div class="tag" @click="showPitchGoods" ref="tag">
                <i class="el-icon-arrow-left" v-show="!openGoods"></i
                ><i class="el-icon-arrow-right" v-show="openGoods"></i>
                <div class="right">
                  <div>已</div>
                  <div>选</div>
                  <span>{{ ids.length }}</span>
                </div>
              </div>
              <div class="pitch-goods" v-show="openGoods">
                <div class="pitch-title">
                  <div class="left">选中的客户</div>
                  <div class="right" @click="delPitchGoods('#')">清空</div>
                </div>
                <el-scrollbar class="scrollbarPitch">
                  <div class="itemList">
                    <div
                      class="item-goods"
                      v-for="item in selectAllData"
                      :key="item.partnerId"
                    >
                      <div class="name">{{ item.partnerName }}</div>
                      <div
                        class="el-icon-close"
                        @click="delPitchGoods(item)"
                      ></div>
                    </div>
                  </div>
                </el-scrollbar>
              </div>
            </div>
            <div class="goods-footer">
              <div class="left">
                <span class="select"
                  >已选<span class="num">{{ this.ids.length }}</span
                  >条</span
                >
                <span class="checkAll" v-show="!isCheckAll" @click="selectAll"
                  >选择全部</span
                >
                <span class="checkAll" v-show="isCheckAll" @click="selectAll"
                  >取消选择</span
                >
              </div>
              <!-- 分页 -->
              <pagination
                v-show="total > 0"
                :total="total"
                :page.sync="queryParams.pageNum"
                :limit.sync="queryParams.pageSize"
                @pagination="getList"
              />
            </div>
          </el-col>
        </el-row>
      </div>
      <span slot="footer">
        <el-button type="primary" @click="getGoods">确 定</el-button>
        <el-button @click="close">取消</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { listCustomer } from '@/api/goods/customer.js' //客户资料
import { listTree } from '@/api/goods/group' //客供分组
export default {
  name: 'selectCustomer',
  props: {
    //商品信息开关
    openCustomerCards: {
      type: Boolean,
      default: false
    },
    // 是否单选
    isRadio: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      //遮罩层
      loading: false,
      // 选中数组
      ids: [],
      // 显示搜索条件
      showSearch: true,
      // 总条数
      total: 0,
      // 当前页商品表格数据
      customerSList: [],
      //所有商品数据
      goodsAllList: [],
      //选中的商品数据
      selectAllData: [],
      // 分类树选项
      customerTreeOptions: [],
      // 分类名称
      categoryName: undefined,
      //判断当前是否全选数据
      isCheckAll: false,
      //是否开启选中商品框
      openGoods: false,
      //定位已选图标的位置
      posiRight: '52px',
      // 查询参数
      queryParams: {
        pageNum: 1,
        pageSize: 15
      },
      //会员卡级别树规则
      defaultProps: {
        children: 'children',
        label: 'label'
      }
    }
  },
  watch: {
    // 根据名称筛选分类树
    categoryName(val) {
      this.$refs.tree.filter(val)
    },
    openCustomerCards(val) {
      if (val) {
        this.queryParams.isCardEmited = this.isCardEmited
        this.getList()
      } else {
        this.reset()
      }
    }
  },
  methods: {
    //点击行选中
    handleRowClick(row) {
      // 获取表格对象
      // 切换行选中状态
      this.$refs.customerTable.toggleRowSelection(row)
    },
    //关闭对话框
    close() {
      this.$emit('update:openCustomerCards', false)
    },
    /** 查询商品列表 */
    async getList() {
      //开启加载
      this.loading = true
      //获取表格数据
      const res1 = await listCustomer(this.queryParams)
      this.customerSList = res1.rows
      this.total = res1.total
      /** 查询分类下拉树结构 */
      const res2 = await listTree()
      this.customerTreeOptions = res2.data
      //关闭加载
      this.loading = false
    },

    // 筛选节点
    filterNode(value, data) {
      if (!value) return true
      return data.label.indexOf(value) !== -1
    },

    // 节点单击事件
    handleNodeClick(data) {
      this.queryParams.groupId = data.id
      this.handleQuery()
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParams.pageNum = 1
      this.getList()
    },
    // 多选框选中数据
    handleSelectionChange(selection) {
      this.selectAllData = selection
      this.ids = selection.map(item => item.partnerId)
    },
    //选中商品
    getGoods() {
      if (this.ids.length >= 1)
        this.$emit('selectCustomerCom', this.selectAllData)
      this.close()
      this.delPitchGoods('#')
    },
    //清空搜索框
    reset() {
      this.categoryName = ''
      this.queryParams = {
        pageNum: 1,
        pageSize: 15
      }
    },
    //显示选中的商品
    showPitchGoods() {
      this.span = this.span === 20 ? 16 : this.span
      this.openGoods = !this.openGoods
      this.posiRight = this.posiRight === '52px' ? '252px' : '52px'
      this.$refs.tag.style.right = this.posiRight
    },
    //点击全选所有数据
    async selectAll() {
      this.isCheckAll = !this.isCheckAll
      if (this.isCheckAll) {
        const res = await listCustomer({
          ...this.queryParams,
          pageNum: 1,
          pageSize: this.total
        })
        this.goodsAllList = res.rows
        this.goodsAllList.map(row => {
          if (this.selectAllData.length) {
            for (let i = 0; i < this.selectAllData.length; i++) {
              if (row.partnerId === this.selectAllData[i].partnerId) return
            }
          }

          this.$nextTick(() => {
            this.$refs.customerTable.toggleRowSelection(row, this.isCheckAll) //全选
          })
        })
      } else {
        this.$nextTick(() => {
          this.$refs.customerTable.clearSelection()
        })
      }
      this.getList()
    },
    //取消选中的商品
    delPitchGoods(row) {
      if (row === '#') {
        this.$nextTick(() => {
          this.$refs.customerTable.clearSelection() //清空选中
        })
      } else {
        this.customerSList.map(item => {
          if (item.partnerId === row.partnerId) {
            this.$nextTick(() => {
              this.$refs.customerTable.toggleRowSelection(item) //取消行选中
            })
          }
        })
      }
    },
    tabSelectAll(selection) {
      if (this.isRadio) {
        console.log('选择数量：', selection.length)
        if (selection.length > 1) {
          selection.length = 1
        }
      }
    },
    tabSelect(selection) {
      if (selection.length > 1) {
        if (this.isRadio) {
          console.log('选择数量：', selection.length)
          let del_row = selection.shift()
          this.$refs.customerTable.toggleRowSelection(del_row, false)
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.select-goods {
  //tree区域
  .head-container {
    margin-right: 20px;
  }

  //左边tree滚动条区域
  .scrollbarTree {
    overflow: hidden;
    height: 500px;
  }

  //中间商品表格/选中商品区域
  .goodsData {
    display: flex;
    //表格区域
    .table-goods {
      flex: 4;
      position: relative;
    }

    //右边选中区域
    .pitch-goods {
      flex: 1;
      height: 500px;
      width: 90%;
      border-top: 1px solid #dfe6ec;
      border-right: 1px solid #dfe6ec;
      border-bottom: 1px solid #dfe6ec;

      .pitch-title {
        display: flex;
        justify-content: space-between;
        padding: 0 5px;
        height: 55px;
        line-height: 55px;
        border-bottom: 1px solid #dfe6ec;
        background-color: #f8f8f9;

        .left {
          font-weight: 700;
        }

        .right {
          color: #1890ff;
          cursor: pointer;
        }
      }

      //滚动条
      .scrollbarPitch {
        overflow: hidden;
        height: 445px;

        .itemList {
          .item-goods {
            padding-right: 8px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: 56px;
            background-image: linear-gradient(to bottom, #fff, #f5f5f5);
          }
        }
      }
    }
  }

  //表格底部区域
  .goods-footer {
    display: flex;
    justify-content: space-between;

    .left {
      padding: 32px 16px;

      .select {
        margin-right: 5px;

        .num {
          color: #1890ff;
        }
      }

      .checkAll {
        color: #1890ff;
        cursor: pointer;
      }
    }
  }

  //选中的图标
  .tag {
    position: absolute;
    transform: translateY(-50%);
    right: 52px;
    top: 50%;
    display: flex;
    cursor: pointer;
    font-size: 16px;
    width: 50px;
    border: 1px solid #dfe6ec;
    border-radius: 5px;
    align-items: center;
    z-index: 9999;
    background-color: #fff;

    .right {
      display: flex;
      flex-direction: column;
      padding: 0 2px;
      text-align: center;
    }

    span {
      padding: 0 6px;
      text-align: center;
      line-height: 25px;
      height: 25px;
      border-radius: 50%;
      background-color: #2386ee;
      color: #fff;
      font-size: 14px;
    }
  }
  ::v-deep .el-dialog__body {
    padding: 0;
  }
}
</style>
