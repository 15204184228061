<template>
  <div>
    <el-dialog
      :visible="OpenIcon"
      title="图标选择器"
      :before-close="close"
      v-dialogDrag="true"
      custom-class="import-dialog"
      width="900px"
    >
      <!-- 整个表格 -->
      <div class="x-x">
        <div class="collapse marR20">
          <el-collapse v-model="activeNames">
            <el-collapse-item title="官方图标库" name="1">
              <div
                class="cursorP padL30 collapseItem"
                :class="{ collapseActive: activeIndex === 0 }"
                @click="getCollapse(0)"
              >
                线性图标
              </div>
              <div
                class="cursorP padL30 collapseItem"
                :class="{ collapseActive: activeIndex === 1 }"
                @click="getCollapse(1)"
              >
                面性图标
              </div>
              <div
                class="cursorP padL30 collapseItem"
                :class="{ collapseActive: activeIndex === 2 }"
                @click="getCollapse(2)"
              >
                其他图标
              </div>
            </el-collapse-item>
          </el-collapse>
        </div>
        <!-- 线性图标 -->
        <div class="linkContent flex1" v-show="activeIndex === 0">
          <!-- 线性图标 -->
          <ul>
            <li
              v-for="(item, index) in lineIconList"
              :key="index"
              :class="{ active: iconIndex === index }"
              class="fontS14c3"
              @mouseover="getLiMouseover(index)"
              @mouseleave="getLiMouseleave"
              @click="getSelectIcon(item)"
            >
              <iconParkALi :name="item" size="25px" />
            </li>
          </ul>
        </div>
        <!-- 面性图标 -->
        <div class="linkContent flex1" v-show="activeIndex === 1">
          <!-- 面性图标 -->
          <ul>
            <li
              v-for="(item, index) in facialIconList"
              :key="index"
              :class="{ active: iconIndex === index }"
              class="fontS14c3"
              @mouseover="getLiMouseover(index)"
              @mouseleave="getLiMouseleave"
              @click="getSelectIcon(item)"
            >
              <iconParkALi :name="item" size="25px" />
            </li>
          </ul>
        </div>
        <!-- 其他图标 -->
        <div class="linkContent flex1" v-show="activeIndex === 2">
          <!-- 面性图标 -->
          <ul>
            <li
              v-for="(item, index) in otherIconList"
              :key="index"
              :class="{ active: iconIndex === index }"
              class="fontS14c3"
              @mouseover="getLiMouseover(index)"
              @mouseleave="getLiMouseleave"
              @click="getSelectIcon(item)"
            >
              <iconParkALi :name="item" size="25px" />
            </li>
          </ul>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { lineIconList, facialIconList, otherIconList } from './icon.js'
export default {
  name: 'selectIcon',
  props: {
    //是否显示
    OpenIcon: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      //鼠标移入图标
      iconIndex: -1,
      //面性图标
      facialIconList: facialIconList,
      //线性图标
      lineIconList: lineIconList,
      //其他图标
      otherIconList: otherIconList,
      //当前打开的折叠面板
      activeNames: ['1'],
      //选中折叠面板
      activeIndex: 0
    }
  },
  watch: {
    OpenIcon () {
      if (!this.OpenIcon) {
        this.reset()
      }
    }
  },
  async created () {},
  methods: {
    //鼠标移出Li标签
    getLiMouseleave () {
      this.iconIndex = -1
    },
    //鼠标移入Li标签
    getLiMouseover (index) {
      this.iconIndex = index
    },
    //Li点击赋值
    getSelectIcon (name) {
      this.$emit('selectIcon', name)
      this.close()
    },
    //点击li标签
    getSelect (indexList, list, index) {
      //跳转路径
      this.selectLinkObj.url = this[list][index].url
      //跳转名称
      this.selectLinkObj.name = this[list][index].name
      //选中时的焦点
      this[indexList] = index
      //重置别的页面数据
      this.selectLinkObj.id = undefined
      //清空商城页面或个人中心的焦点
      this[indexList === 'liShopIndex' ? 'liMyIndex' : 'liShopIndex'] = -1
    },
    //获折叠面板取焦点
    getCollapse (index) {
      this.activeIndex = index
    },
    //关闭对话框
    close () {
      this.$emit('update:OpenIcon', false)
    },
    //清空搜索框
    reset () {
      //当前打开的折叠面板
      this.activeNames = ['1']
      //选中折叠面板
      this.activeIndex = 0
      //移入下标
      this.iconIndex = -1
    }
  }
}
</script>

<style lang="scss" scoped>
.collapse {
  width: 158px;
  //子菜单
  .collapseItem {
    height: 48px;
    line-height: 48px;
  }
  //选中菜单
  .collapseActive {
    background-color: #e9f2fd;
    color: #1890ff;
  }

  ::v-deep .el-collapse-item__header {
    padding-left: 20px;
  }
  ::v-deep .el-collapse-item__content {
    padding-bottom: 0;
  }
}
//链接内容
.linkContent {
  height: 600px;
  overflow-y: auto;
  //菜单样式ul
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    gap: 16px;
    display: flex;
    flex-wrap: wrap;
    li {
      list-style: none;
      margin: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: normal;
      cursor: pointer;
      border: 1px solid #f0f0f0;
      width: 68px;
      height: 68px;
    }
    .active {
      box-shadow: 0 3px 10px rgba(0, 0, 0, 0.1);
    }
  }
}
//弹出层的高度
::v-deep .el-dialog.import-dialog {
  height: 670px;
  overflow-y: auto;
}
::v-deep .el-dialog.import-dialog {
  overflow-y: visible;
}
</style>
