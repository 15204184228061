var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "selectProductDialog" },
    [
      _c("c-dialog", {
        attrs: { title: _vm.title, showDialog: _vm.openDialog, width: 1200 },
        on: {
          "update:showDialog": function ($event) {
            _vm.openDialog = $event
          },
          "update:show-dialog": function ($event) {
            _vm.openDialog = $event
          },
        },
        scopedSlots: _vm._u([
          {
            key: "content",
            fn: function () {
              return [
                _c(
                  "div",
                  { staticClass: "search x-f" },
                  [
                    _c("el-input", {
                      attrs: {
                        size: "mini",
                        placeholder: "请输入产品名称",
                        clearable: "",
                      },
                      nativeOn: {
                        keyup: function ($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          ) {
                            return null
                          }
                          return _vm.getList()
                        },
                      },
                      model: {
                        value: _vm.queryParams.productName,
                        callback: function ($$v) {
                          _vm.$set(_vm.queryParams, "productName", $$v)
                        },
                        expression: "queryParams.productName",
                      },
                    }),
                    _c(
                      "el-button",
                      {
                        attrs: {
                          type: "primary",
                          icon: "el-icon-search",
                          size: "mini",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.getList()
                          },
                        },
                      },
                      [_vm._v("搜索")]
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "table" },
                  [
                    _c(
                      "el-table",
                      {
                        directives: [
                          {
                            name: "loading",
                            rawName: "v-loading",
                            value: _vm.loading,
                            expression: "loading",
                          },
                        ],
                        ref: "multipleTable",
                        attrs: {
                          border: "",
                          data: _vm.productList,
                          "row-key": "productId",
                          height: "570",
                        },
                        on: {
                          "selection-change": _vm.handleSelectionChange,
                          "select-all": _vm.tabSelectAll,
                          select: _vm.tabSelect,
                        },
                      },
                      [
                        _c("el-table-column", {
                          attrs: {
                            width: "55",
                            type: "selection",
                            align: "center",
                          },
                        }),
                        _c("el-table-column", {
                          attrs: {
                            prop: "productName",
                            label: "产品名称",
                            align: "center",
                            "show-overflow-tooltip": true,
                            width: "260",
                          },
                        }),
                        _c("el-table-column", {
                          attrs: {
                            prop: "logoUrl",
                            label: "产品图片",
                            align: "center",
                            width: "100",
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c("img", {
                                    staticStyle: {
                                      width: "50px",
                                      "object-fit": "cover",
                                      height: "50px",
                                    },
                                    attrs: { src: scope.row.logoUrl },
                                  }),
                                ]
                              },
                            },
                          ]),
                        }),
                        _c("el-table-column", {
                          attrs: {
                            width: "120",
                            prop: "productCalcType",
                            label: "计费模式",
                            align: "center",
                            "show-overflow-tooltip": true,
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        scope.row.productCalcType == 1
                                          ? "包年包月"
                                          : "数量或次数"
                                      )
                                    ),
                                  ]),
                                ]
                              },
                            },
                          ]),
                        }),
                        _c("el-table-column", {
                          attrs: {
                            prop: "initializeUsers",
                            label: "初始用户数",
                            align: "center",
                            width: "120",
                            "show-overflow-tooltip": true,
                          },
                        }),
                        _c("el-table-column", {
                          attrs: {
                            prop: "maxUsers",
                            label: "最大用户数",
                            align: "center",
                            width: "120",
                            "show-overflow-tooltip": true,
                          },
                        }),
                        _c("el-table-column", {
                          attrs: {
                            prop: "initializeShops",
                            label: "初始门店数",
                            align: "center",
                            width: "120",
                            "show-overflow-tooltip": true,
                          },
                        }),
                        _c("el-table-column", {
                          attrs: {
                            prop: "maxShops",
                            label: "最大门店数",
                            align: "center",
                            width: "120",
                            "show-overflow-tooltip": true,
                          },
                        }),
                        _c("el-table-column", {
                          key: "status",
                          attrs: {
                            prop: "status",
                            label: "上线状态",
                            align: "center",
                            width: "120",
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c("el-switch", {
                                    attrs: {
                                      disabled: "",
                                      "active-value": "0",
                                      "inactive-value": "1",
                                    },
                                    model: {
                                      value: scope.row.status,
                                      callback: function ($$v) {
                                        _vm.$set(scope.row, "status", $$v)
                                      },
                                      expression: "scope.row.status",
                                    },
                                  }),
                                ]
                              },
                            },
                          ]),
                        }),
                        _c("el-table-column", {
                          attrs: {
                            prop: "sort",
                            label: "排序",
                            align: "center",
                            width: "100",
                          },
                        }),
                        _c("el-table-column", {
                          attrs: {
                            label: "创建时间",
                            width: "155",
                            align: "center",
                            prop: "createTime",
                          },
                        }),
                      ],
                      1
                    ),
                    _c("pagination", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.total > 0,
                          expression: "total > 0",
                        },
                      ],
                      attrs: {
                        total: _vm.total,
                        page: _vm.queryParams.pageNum,
                        limit: _vm.queryParams.pageSize,
                      },
                      on: {
                        "update:page": function ($event) {
                          return _vm.$set(_vm.queryParams, "pageNum", $event)
                        },
                        "update:limit": function ($event) {
                          return _vm.$set(_vm.queryParams, "pageSize", $event)
                        },
                        pagination: function ($event) {
                          return _vm.getList()
                        },
                      },
                    }),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "buttonList",
            fn: function () {
              return [
                _c(
                  "el-button",
                  { attrs: { size: "mini" }, on: { click: _vm.close } },
                  [_vm._v("取 消")]
                ),
                _c(
                  "el-button",
                  {
                    attrs: { size: "mini", type: "primary" },
                    on: { click: _vm.onSelect },
                  },
                  [_vm._v("确 定")]
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }