<template>
  <!-- 搜索下拉框 -->
  <div class="select-frame">
    <!-- 搜索框 -->
    <el-input
      class="selectVal"
      placeholder="请选择"
      suffix-icon="el-icon-search"
      :value="value"
      @focus="selectRate($event, true)"
      @blur="selectRate($event, false)"
      @input="setVal"
      @keyup.enter.native="handleEnter"
      :disabled="disabled"
      ref="inputBlur"
      :size="size"
    ></el-input>
    <!-- 下拉表格数据 -->
    <div class="choose-box" v-show="showDropDown" tabindex="0">
      <el-table
        :data="optionList"
        border
        style="width: 100%"
        height="260"
        @row-click="getRow"
        highlight-current-row
        ref="table"
      >
        <el-table-column
          :prop="No"
          label="编号"
          style="width: 50%"
          align="center"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          :prop="Name"
          label="名称"
          style="width: 50%"
          align="center"
          show-overflow-tooltip
        ></el-table-column>
      </el-table>
      <div class="choose-btn-box">
        <span class="leftBtn" @click="handleAdd">
          <i class="el-icon-circle-plus-outline"></i>
          新增
        </span>
        <span class="rightBtn" @click="viewMore">
          <i class="el-icon-more-outline"></i>
          查看更多
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "selectFrameUp",
  props: {
    //下拉表格数据
    tableData: {
      required: true,
      type: Array,
    },
    //显示内容
    displayContent: {
      type: String,
      default: "Name",
    },
    //是否禁用
    disabled: {
      type: Boolean,
      default: false,
    },
    //搜索框输入的值
    value: {
      default: "",
    },
    //新增跳转的路由路径
    router: {
      type: String,
    },
    //字段转换
    normalizer: {
      type: Object,
    },
    size: {
      type: String,
    },
  },
  data() {
    return {
      showDropDown: false, //是否显示下拉框开关
      No: "No", //默认编码字段
      Id: "Id", //默认id的字段
      Name: "Name", //默认名称的字段
    };
  },
  watch: {
    //默认高亮
    optionList: function () {
      this.$nextTick(function () {
        this.$refs.table.setCurrentRow(this.optionList[0]);
      });
    },
    //侦听需要转换的字段 进行转换
    normalizer: {
      handler(newVal) {
        for (let key in newVal) {
          if (key === this.No) this.No = newVal[key];
          if (key === this.Id) this.Id = newVal[key];
          if (key === this.Name) this.Name = newVal[key];
        }
      },
      immediate: true,
    },
    //侦听value是不是编辑传过来的 是直接赋值
    value: {
      handler(val) {
        if (this.tableData.length > 0) {
          const bol = this.tableData.some((v) => v[this.Name] === val); //传过来的值是绝对匹配  bol为true绝对匹配  不需要显示下拉框
          // if (val === undefined || val === null) val = parseStrEmpty(val) // 转换字符串，undefined,null转化为""
          // if (!bol && val != '') this.selectRate(val, true) // val不等于空,值为空不需要展开下拉框且bol为false模糊匹配 是正在编辑搜索 模糊搜索需要弹出输入框
        }
      },
    },
  },
  computed: {
    // 自定义搜索过滤显示数据
    optionList() {
      if (!this.value) {
        return this.tableData;
      }
      const arr = this.tableData.filter((v) => {
        return (
          v[this.No].indexOf(this.value) !== -1 ||
          v[this.Name].indexOf(this.value) !== -1
        ); // 字符串是否包含该字符
      });
      return arr;
    },
  },
  methods: {
    //输入框回车事件
    handleEnter() {
      //筛选表格绝对匹配的对象
      if (this.optionList.length > 0) {
        //传递当前行 给父组件
        this.$emit("selectRowObj", this.optionList[0]);
        if (this.displayContent == "Name") {
          // 拿到被点的节点编码，赋值给文本框
          this.setVal(this.optionList[0][this.Name]);
        } else if (this.displayContent == "No") {
          // 拿到被点的节点名字，赋值给文本框
          this.setVal(this.optionList[0][this.No]);
        }
        //传递id 给父组件
        this.$emit("getPitchId", this.optionList[0][this.Id]);
        //传递当前行 给父组件
        this.$emit("selectRowObj", this.optionList[0]);
        this.showDropDown = false;
        this.$refs.inputBlur.blur();
      }
    },
    //搜索值变化
    selectRate(event, bool) {
      //获得焦点显示 失去焦点隐藏
      if (!bool) {
        //失去焦点并且输入框是打开状态

        if (this.showDropDown) {
          //失去焦点时 判断下一次获得焦点是不是下拉框的div  是则直接return 不隐藏
          if (event.relatedTarget) {
            if (event.relatedTarget.classList.contains("choose-box")) return;
          }
          //失去焦点时 没有选择某一项 直接赋值空
          this.getRow("");
        }
        this.showDropDown = false;
      } else {
        //获得焦点时 判断是否绝对匹配 是不打开下拉框  否打开下拉框
        let val = undefined;
        if (this.displayContent == "Name") {
          val = this.tableData.some((v) => v[this.Name] === this.value); // 字符串是否包含该字符
        } else if (this.displayContent == "No") {
          val = this.tableData.some((v) => v[this.No] === this.value); // 字符串是否包含该编码
        }
        bool = !val;
        this.showDropDown = true;
      }
    },
    //下拉框行点击事件
    getRow(row) {
      if (this.displayContent == "Name") {
        // 拿到被点的节点编码，赋值给文本框
        this.setVal(row[this.Name]);
      } else if (this.displayContent == "No") {
        // 拿到被点的节点名字，赋值给文本框
        this.setVal(row[this.No]);
      }
      //隐藏下拉框
      this.showDropDown = false;
      //传递id 给父组件
      this.$emit("getPitchId", row[this.Id]);
      //传递当前行 给父组件
      this.$emit("selectRowObj", row);
    },
    //设置v-model 设置input的值
    setVal(event) {
      this.$emit("input", event);
    },
    //点击查看更多事件
    viewMore() {
      this.$emit("click", "");
      //隐藏下拉框
      this.showDropDown = false;
    },
    //新增事件
    handleAdd() {
      if (this.router) {
        //有路径传过来 就直接跳转到路径处新增
        this.$router.push(this.router);
      } else {
        //没有就直接传点击事件使父组件开启新增对话框
        this.$emit("clickAdd", "");
      }
      //隐藏下拉框
      this.showDropDown = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.select-frame {
  .selectVal {
    width: 170px;
    // position: relative;
  }
  .choose-box {
    min-width: 360px;
    margin-top: 5px;
    z-index: 3000;
    position: fixed;
    // position: absolute;
    box-shadow: 0 2px 8px rgb(0 0 0 / 9%);
    .choose-btn-box {
      display: flex;
      width: 100%;
      height: 40px;
      line-height: 40px;
      font-size: 14px;
      background-color: #ffffff;
      .leftBtn,
      .rightBtn {
        width: 50%;
        text-align: center;
        cursor: pointer;
      }
      .leftBtn {
        border-right: 1px solid #e6ebf5;
      }
    }
  }
}
</style>
