<template>
  <div>
    <el-dialog
      :visible="OpenDateTime"
      title="时间日期选择"
      :before-close="close"
      v-dialogDrag="true"
      width="32%"
    >
      <!-- 指定周 -->
      <div>
        <span class="weeks">指定周： </span>
        <el-radio-group v-model="dateTimeForm[0].specialDateWeekMode">
          <el-radio
            v-for="dict in dict.type.special_date_week_mode"
            :key="dict.value"
            :label="dict.value"
            >{{ dict.label }}</el-radio
          >
        </el-radio-group>
        <span> 第 </span>
        <el-input
          class="inputWeeks"
          :disabled="dateTimeForm[0].specialDateWeekMode != '2'"
          v-model.number="dateTimeForm[0].specialDateWeekNum"
        />
        <span> 周 </span>
      </div>
      <!-- 指定星期 -->
      <div class="weeksDay">
        <span class="weeks">指定星期： </span>
        <el-checkbox-group
          v-model="dateTimeForm[1].specialDateWeeksArr"
          @change="handleCheckedCitiesChange"
        >
          <el-checkbox v-for="city in cities" :label="city" :key="city">{{
            city
          }}</el-checkbox>
        </el-checkbox-group>
        <el-checkbox
          :indeterminate="isIndeterminate"
          v-model="checkAll"
          @change="handleCheckAllChange"
          >全选</el-checkbox
        >
      </div>
      <!-- 每月期间 -->
      <div>
        <span class="weeks">每月期间： </span>
        <!-- 月前 -->
        <el-checkbox v-model="dateTimeForm[2].isTopMonthPeriod"
          >每月第</el-checkbox
        >
        <el-input
          class="inputWeeks marL15"
          v-model.number="dateTimeForm[2].topFromDay"
        />
        <span class="marL15">至</span>
        <el-input
          class="inputWeeks marL15"
          v-model.number="dateTimeForm[2].topToDay"
        />
        <span> 天</span>
      </div>
      <!-- 月后 -->
      <div class="endMonth">
        <el-checkbox v-model="dateTimeForm[2].isDownMonthPeriod"
          >每月最后</el-checkbox
        >
        <el-input
          class="inputWeeks marL15"
          v-model.number="dateTimeForm[2].downFromDay"
        />
        <span class="marL15">至</span>
        <el-input
          class="inputWeeks marL15"
          v-model.number="dateTimeForm[2].downToDay"
        />
        <span>天</span>
      </div>
      <!-- 日期范围 -->
      <div>
        <span class="weeks">日期范围：</span>
        <el-date-picker
          class="dataTime"
          v-model="datetime"
          type="daterange"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          value-format="yyyy-MM-dd"
        />
      </div>
      <!-- 指定时段 -->
      <div class="marT15">
        <span class="weeks">指定时段：</span>
        <el-time-picker
          is-range
          v-model="timePeriod"
          range-separator="至"
          start-placeholder="开始时间"
          end-placeholder="结束时间"
          placeholder="选择时间范围"
          value-format="HH:mm:ss"
        />
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="close">取消</el-button>
        <el-button type="primary" @click="getConfirm">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
const cityOptions = [
  '星期一',
  '星期二',
  '星期三',
  '星期四',
  '星期五',
  '星期六',
  '星期日'
]
export default {
  dicts: ['special_date_week_mode'],
  props: {
    //商品信息开关
    OpenDateTime: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      timePeriod: '', //时间段
      datetime: '', //日期时间
      checkAll: false,
      isIndeterminate: false,
      cities: cityOptions,
      dateTimeForm: [
        { specialDateWeekMode: undefined, specialDateWeekNum: undefined },
        { specialDateWeeksArr: [] },
        {
          isTopMonthPeriod: false,
          isDownMonthPeriod: false,
          topFromDay: undefined,
          topToDay: undefined,
          downFromDay: undefined,
          downToDay: undefined
        },
        { specialBegDate: undefined, specialEndDate: undefined },
        { specialBegTime: undefined, specialEndTime: undefined }
      ] //日期
    }
  },

  watch: {
    OpenDateTime (val) {
      if (!val) {
        this.reset()
      }
    },
    //日期范围
    datetime (newVal) {
      if (newVal) {
        this.dateTimeForm[3].specialBegDate = newVal[0]
        this.dateTimeForm[3].specialEndDate = newVal[1]
      } else {
        this.dateTimeForm[3].specialBegDate = undefined
        this.dateTimeForm[3].specialEndDate = undefined
      }
    },
    //时间段
    timePeriod (newVal) {
      if (newVal) {
        this.dateTimeForm[4].specialBegTime = newVal[0]
        this.dateTimeForm[4].specialEndTime = newVal[1]
      } else {
        this.dateTimeForm[4].specialBegTime = undefined
        this.dateTimeForm[4].specialEndTime = undefined
      }
    }
  },
  methods: {
    handleCheckedCitiesChange (value) {
      let checkedCount = value.length
      this.checkAll = checkedCount === this.cities.length
      this.isIndeterminate =
        checkedCount > 0 && checkedCount < this.cities.length
    },
    handleCheckAllChange (val) {
      this.dateTimeForm[1].specialDateWeeksArr = val ? cityOptions : []
      this.isIndeterminate = false
    },
    //确定按钮
    getConfirm () {
      //每月期间
      if (this.dateTimeForm[2].isTopMonthPeriod) {
        if (
          !this.dateTimeForm[2].topFromDay ||
          !this.dateTimeForm[2].topToDay
        ) {
          return this.$message.error('请选择日期时间信息')
        }
      }
      //每月最后
      if (this.dateTimeForm[2].isDownMonthPeriod) {
        if (
          !this.dateTimeForm[2].downFromDay ||
          !this.dateTimeForm[2].downToDay
        ) {
          return this.$message.error('请选择日期时间信息')
        }
      }
      if (this.dateTimeForm) {
        this.$emit('DateTimeList', this.dateTimeForm)
      }
      this.close()
    },
    //关闭对话框
    close () {
      this.$emit('update:OpenDateTime', false)
    },

    //清空搜索框
    reset () {
      this.timePeriod = '' //时间段
      this.datetime = '' //日期时间
      this.checkAll = false
      this.isIndeterminate = false
      this.dateTimeForm = [
        { specialDateWeekMode: undefined, specialDateWeekNum: undefined },
        { specialDateWeeksArr: [] },
        {
          isTopMonthPeriod: false,
          isDownMonthPeriod: false,
          topFromDay: undefined,
          topToDay: undefined,
          downFromDay: undefined,
          downToDay: undefined
        },
        { specialBegDate: undefined, specialEndDate: undefined },
        { specialBegTime: undefined, specialEndTime: undefined }
      ] //日期
    }
  }
}
</script>

<style lang="scss" scoped>
//标题
.weeks {
  margin: 20px 20px;
  width: 130px;
}
//输入框
.inputWeeks {
  width: 100px;
}

.weeksDay {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80px;
}
//月末
.endMonth {
  margin: 20px 0 20px 115px;
}
//日期范围
.dataTime {
  width: 360px;
}
</style>
