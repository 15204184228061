var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "search-box" },
    [
      _c(
        "el-input",
        {
          staticClass: "inputSearch",
          attrs: {
            size: "mini",
            type: "text",
            placeholder: _vm.placeholderText,
          },
          on: {
            input: _vm.handleInput,
            blur: _vm.handleBlur,
            keydown: [
              function ($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "down", 40, $event.key, [
                    "Down",
                    "ArrowDown",
                  ])
                ) {
                  return null
                }
                return _vm.handleKeyDown($event)
              },
              function ($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "up", 38, $event.key, [
                    "Up",
                    "ArrowUp",
                  ])
                ) {
                  return null
                }
                return _vm.handleKeyDown($event)
              },
            ],
          },
          nativeOn: {
            keyup: function ($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
              ) {
                return null
              }
              return _vm.handleEnter($event)
            },
          },
          model: {
            value: _vm.searchText,
            callback: function ($$v) {
              _vm.searchText = $$v
            },
            expression: "searchText",
          },
        },
        [
          _c("i", {
            staticClass: "el-input__icon el-icon-search",
            attrs: { slot: "prefix" },
            slot: "prefix",
          }),
        ]
      ),
      _c(
        "ul",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.showDropDown,
              expression: "showDropDown",
            },
          ],
          ref: "clickLiList",
        },
        _vm._l(_vm.dropDownList, function (item, index) {
          return _c(
            "li",
            {
              key: index,
              staticClass: "fontS14",
              class: { active: index === _vm.activeIndex },
              on: {
                click: function ($event) {
                  return _vm.handleItemClick(index)
                },
              },
            },
            [
              _vm._v(" " + _vm._s(item.name) + " "),
              _c("span", { staticClass: "marR10" }, [_vm._v(":")]),
              _c("span", { staticClass: "fontS14" }, [
                _vm._v(_vm._s(_vm.searchText)),
              ]),
            ]
          )
        }),
        0
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }