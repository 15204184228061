<template>
  <div class="select-store">
    <el-dialog
      :visible="OpenStore"
      title="仓库列表"
      v-dialogDrag="true"
      width="1100px"
      height="600px"
      :before-close="handleClose"
      custom-class="store-dialog"
    >
      <div class="theBody">
        <!--部门数据-->

        <div class="head-container">
          <div class="searchOpentitle">
            <el-input
              class="searchTree"
              placeholder="输入关键字进行过滤"
              v-model="filterText"
              size="small"
              prefix-icon="el-icon-search"
              style="margin-bottom: 10px"
            ></el-input>
            <i
              class="el-icon-arrow-down iconDown"
              v-show="!isOpenTree"
              @click="clickOpenTree"
            ></i>
            <i
              class="el-icon-arrow-up iconDown"
              v-show="isOpenTree"
              @click="clickOpenTree"
            ></i>
          </div>

          <div class="tree-container">
            <el-tree
              highlight-current
              :data="depOptions"
              node-key="id"
              :props="defaultProps"
              :filter-node-method="filterNode"
              :default-expand-all="isExpand"
              ref="tree"
              @node-click="handleNodeClick"
              :expand-on-click-node="false"
            ></el-tree>
          </div>
        </div>

        <!--仓库资料数据-->
        <div class="tableBody">
          <div class="search">
            <el-input
              v-model="queryParams.storeName"
              placeholder="请输入仓库名称"
              clearable
              style="width: 240px"
              @keyup.enter.native="handleQuery"
            />
            <el-button
              type="primary"
              class="el-icon-search searchBtn"
              size="mini"
              @click="handleQuery"
              >搜索</el-button
            >
          </div>
          <el-table
            v-loading="loading"
            :data="storeList"
            border
            tooltip-effect="dark"
            style="width: 100%"
            height="500"
            max-height="500"
            @selection-change="handleSelectionStore"
            ref="multipleTable"
          >
            <el-table-column
              width="55"
              align="center"
              type="selection"
              v-if="isMultiple"
            />
            <el-table-column width="55" align="center" v-else>
              <template v-slot="scope">
                <el-radio v-model="SelectStore" :label="scope.row">{{
                  ''
                }}</el-radio>
              </template>
            </el-table-column>
            <el-table-column
              label="序号"
              width="80"
              type="index"
              align="center"
              key="sortNo1"
              prop="sortNo1"
            />
            <el-table-column
              prop="storeNo"
              label="仓库编码"
              :show-overflow-tooltip="true"
              align="center"
              width="100"
              key="storeNo"
            >
              <template slot-scope="scope">
                <el-link type="primary" target="_blank">{{
                  scope.row.storeNo
                }}</el-link>
              </template>
            </el-table-column>

            <el-table-column
              prop="storeName"
              label="仓库名称"
              :show-overflow-tooltip="true"
              align="center"
              key="storeName"
              width="120"
            ></el-table-column>

            <el-table-column
              label="所属机构"
              align="center"
              key="deptName"
              prop="deptName"
              :show-overflow-tooltip="true"
            />

            <el-table-column label="仓库类型" align="center" prop="storeType">
              <template slot-scope="scope">
                <dict-tag
                  :options="dict.type.store_type"
                  :value="scope.row.storeType"
                />
              </template>
            </el-table-column>

            <el-table-column label="仓库状态" align="center" prop="status">
              <template slot-scope="scope">
                <dict-tag
                  :options="dict.type.store_status"
                  :value="scope.row.status"
                />
              </template>
            </el-table-column>

            <el-table-column
              label="地址"
              align="center"
              prop="storeAddress"
              :show-overflow-tooltip="true"
            />
            <el-table-column
              label="联系人"
              align="center"
              key="userName"
              prop="userName"
              :show-overflow-tooltip="true"
            />
            <el-table-column
              label="联系电话"
              align="center"
              key="storeTel"
              prop="storeTel"
              width="110"
              :show-overflow-tooltip="true"
            />

            <el-table-column
              label="创建人"
              align="center"
              prop="createBy"
              :show-overflow-tooltip="true"
            />
            <el-table-column
              label="创建时间"
              align="center"
              prop="createTime"
              width="155"
              :show-overflow-tooltip="true"
            />
            <el-table-column
              label="修改人"
              align="center"
              prop="updateBy"
              :show-overflow-tooltip="true"
            />
            <el-table-column
              label="修改时间"
              align="center"
              prop="updateTime"
              width="155"
              :show-overflow-tooltip="true"
            />
            <el-table-column label="删除状态" align="center" prop="delFlag">
              <template slot-scope="scope">
                <dict-tag
                  :options="dict.type.App_del_flag"
                  :value="scope.row.delFlag"
                />
              </template>
            </el-table-column>
            <el-table-column
              label="备注"
              align="center"
              prop="remark"
              :show-overflow-tooltip="true"
            />
          </el-table>

          <pagination
            v-show="total > 0"
            :total="total"
            :page.sync="queryParams.pageNum"
            :limit.sync="queryParams.pageSize"
            @pagination="getList"
          />
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" @click="getStore">{{
          confirmText
        }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { treeselect as getDepTree } from '@/api/system/dept'
import { listStore } from '@/api/goods/store'
export default {
  name: 'storeSetup',
  dicts: ['store_status', 'store_type', 'App_del_flag'],
  data () {
    return {
      storeSelectionList: [] //选中的仓库名称
    }
  },
  props: {
    //确定按钮
    billType: {
      type: String,
      default: ''
    },
    confirmText: {
      type: String,
      default: '确 定'
    },
    /* 已经选择的仓库信息 */
    selectStoreList: {
      type: Array,
      default: function () {
        return []
      }
    },
    //弹出框开关
    OpenStore: {
      type: Boolean,
      default: false
    },
    //单选多选
    isMultiple: {
      type: Boolean,
      default: false
    },
    //重置搜索条件
    refreshSearch: {
      type: Object,
      default: function () {
        return {
          pageNum: 1,
          pageSize: 15
        }
      }
    }
  },
  data () {
    return {
      storeSelectionList: [], //选中的仓库名称
      SelectStore: undefined, //选中的仓库
      isExpand: true, //是否默认展开
      isOpenTree: true, //一键展开
      filterText: '', //节点过滤
      // 遮罩层
      loading: true,
      // 显示搜索条件
      showSearch: true,
      // 总条数
      total: 0,
      // 仓库资料表格数据
      storeList: [],
      // 部门树选项
      depOptions: [],
      // 部门名称
      deptName: null,
      delFlag: null, //删除标志
      //树规则
      defaultProps: {
        children: 'children',
        label: 'label'
      },
      // 查询参数
      queryParams: {
        pageNum: 1,
        pageSize: 15
      }
    }
  },
  watch: {
    // 根据名称筛选部门树
    filterText (val) {
      this.$refs.tree?.filter(val)
    },
    //侦听是否打开对话框
    OpenStore: {
      async handler (val) {
        if (val) {
          this.queryParams = JSON.parse(JSON.stringify(this.refreshSearch))
          await this.getList()
          await this.getDepTree()
          let array = []
          this.selectStoreList.forEach(v => {
            array.push({ storeId: v.orderStoreId })
          })
          let arr = []
          this.storeList.forEach(v => {
            if (array.length > 0) {
              for (let index = 0; index < array.length; index++) {
                if (array[index].storeId == v.storeId) {
                  arr.push(v)
                }
              }
            } else {
              return
            }
          })
          arr.forEach(row => {
            this.$nextTick(() => {
              this.$refs.multipleTable.toggleRowSelection(row, true)
            })
          })
        }
      },
      immediate: true
    }
  },
  methods: {
    //对话框关闭前的回调
    handleClose () {
      this.$emit('update:OpenStore', false)
    },
    //一键展开
    clickOpenTree () {
      this.isOpenTree = !this.isOpenTree
      this.isExpand = !this.isExpand
      this.buildData()
    },
    //遍历树的所有子节点，展开的时候给子节点展开状态赋值true，折叠时候赋值false
    buildData () {
      for (let i = 0; i < this.$refs.tree.store._getAllNodes().length; i++) {
        this.$refs.tree.store._getAllNodes()[i].expanded = this.isExpand
      }
    },

    /** 查询仓库列表 */
    async getList () {
      this.loading = true
      if (['140303','140313','140312'].includes(this.billType)) {
        this.queryParams.storeTypes = [1, 2]
      }
      const response = await listStore(this.queryParams)
      this.storeList = response.rows
      this.total = response.total
      this.loading = false
    },
    /** 查询部门下拉树结构 */
    getDepTree () {
      getDepTree().then(response => {
        this.depOptions = response.data
      })
    },

    //搜索按钮
    handleQuery () {
      this.queryParams.pageNum = 1
      this.getList()
    },

    // 筛选节点
    filterNode (value, data) {
      if (!value) return true
      return data.label.indexOf(value) !== -1
    },

    // 节点单击事件
    handleNodeClick (data) {
      this.queryParams = {
        pageNum: 1,
        pageSize: 15,
        storeNo: null,
        storeName: null,
        status: null,
        deptId: null
      }
      this.queryParams.deptId = data.id
      this.handleQuery()
    },
    // 多选框选中数据
    handleSelectionStore (selection) {
      // const delFlagStatus = selection.some(v => v.delFlag == '1')
      // this.delFlag = delFlagStatus //删除标志
      // this.ids = selection.map(item => item.storeId)
      this.storeSelectionList = selection
      // this.single = selection.length != 1
      // this.single = !selection.length
    },
    //选中的仓库
    getStore () {
      // console.log('------', this.storeSelectionList)
      if (this.SelectStore) this.$emit('getStore', this.SelectStore)
      if (this.storeSelectionList.length > 0)
        this.$emit('storeSelectionList', this.storeSelectionList)
      //清空选中的单选仓库
      this.SelectStore = undefined
      //清空选中的仓库的名称
      this.storeSelectionList = []
      //关闭对话框
      this.handleClose()
    }
  }
}
</script>

<style scoped lang="scss">
//整体盒子
.theBody {
  display: flex;
  height: 600px;
  .search {
    text-align: right;
    margin-bottom: 10px;
    .searchBtn {
      margin-left: 10px;
    }
  }

  .tableBody {
    min-width: 0;
    display: flex;
    flex-direction: column;
    margin-left: 10px;
  }
}
//整体树
.head-container {
  .tree-container {
    height: 538px;
    overflow-y: scroll;
  }
  //搜索框那一行
  .searchOpentitle {
    display: flex;
    //搜索tree
    .searchTree {
      width: 230px;
    }
    //一键展开
    .iconDown {
      // width: 20px;
      font-size: 16px;
      margin-left: 10px;
      cursor: pointer;
      text-align: center;
      margin-top: 7px;
    }
  }
}
.address {
  width: 100%;
}

.select-store {
  ::v-deep .el-dialog__body {
    padding: 12px 24px 0 24px;
  }
}
</style>
